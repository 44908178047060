import React, { useEffect } from "react";
import {
  Stack,
  Button,
  TextField,
  Rating,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { FormInputMultiCheckbox } from "../../../components/form/FormInputMultiCheckbox";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  saveExitQuestionnaire,
  getExitQuestionnaire,
} from "./ExitFragebogenAPI";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../store/auth/auth.context";
import { ErrorMessage } from "../../../components/form";

const STATUS_ID = 4;

const defaultValues = {
  Q1: 0,
  Q2: "",
  Q3: "",
  Q4: "",
  Q5: "",
  Q6: "",
  Q7: [],
  Q8: "",
  Q9: [],
  Q10: "",
  Q11: "",
  Q12: "",
  Q13: "",
  Q14: "",
  Q15: "",
  Q16: [],
  Q17: "",
  Q18: "",
  Q19: 0,
};

const ExitForm = ({ t, i18n }) => {
  const schema = yup.object({
    Q1: yup
      .number()
      .min(1, t("error_msg.mandatory_field"))
      .required(t("error_msg.mandatory_field")),
    Q2: yup.string().required(t("error_msg.mandatory_field")),
    Q3: yup.string().required(t("error_msg.mandatory_field")),
    Q4: yup.string().required(t("error_msg.mandatory_field")),
    Q5: yup.string().required(t("error_msg.mandatory_field")),
    Q6: yup.string().required(t("error_msg.mandatory_field")),
    Q7: yup.array().min(1).required(t("error_msg.mandatory_field")),
    Q8: yup.string().notRequired(),
    Q9: yup.array().min(1).required(t("error_msg.mandatory_field")),
    Q10: yup.string().notRequired(),
    Q11: yup.string().required(t("error_msg.mandatory_field")),
    Q12: yup.string().required(t("error_msg.mandatory_field")),
    Q13: yup.string().required(t("error_msg.mandatory_field")),
    Q14: yup.string().required(t("error_msg.mandatory_field")),
    Q15: yup.string().required(t("error_msg.mandatory_field")),
    Q16: yup.array().min(1).required(t("error_msg.mandatory_field")),
    Q17: yup.string().notRequired(),
    Q18: yup.string().required(t("error_msg.mandatory_field")),
    Q19: yup
      .number()
      .min(1, t("error_msg.mandatory_field"))
      .required(t("error_msg.mandatory_field")),
  });
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(schema) });
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { authState, dispatch } = useAuth();

  const optionsQ7 = [
    {
      label: t("exit.answers.question7.answer1"),
      value: "1",
    },
    {
      label: t("exit.answers.question7.answer2"),
      value: "2",
    },
    {
      label: t("exit.answers.question7.answer3"),
      value: "3",
    },
    {
      label: t("exit.answers.question7.answer4"),
      value: "4",
    },
    {
      label: t("exit.answers.question7.answer5"),
      value: "5",
    },
    {
      label: t("exit.answers.question7.answer6"),
      value: "6",
    },
    {
      label: t("exit.answers.question7.answer7"),
      value: "7",
    },
    {
      label: t("exit.answers.question7.answer8"),
      value: "8",
    },
  ];

  const optionsQ9 = [
    {
      label: t("exit.answers.question9.answer1"),
      value: "1",
    },
    {
      label: t("exit.answers.question9.answer2"),
      value: "2",
    },
    {
      label: t("exit.answers.question9.answer3"),
      value: "3",
    },
    {
      label: t("exit.answers.question9.answer4"),
      value: "4",
    },
    {
      label: t("exit.answers.question9.answer5"),
      value: "5",
    },
  ];

  const optionsQ16 = [
    {
      label: t("exit.answers.question16.answer1"),
      value: "1",
    },
    {
      label: t("exit.answers.question16.answer2"),
      value: "2",
    },
    {
      label: t("exit.answers.question16.answer3"),
      value: "3",
    },
    {
      label: t("exit.answers.question16.answer4"),
      value: "4",
    },
    {
      label: t("exit.answers.question16.answer5"),
      value: "5",
    },
    {
      label: t("exit.answers.question16.answer6"),
      value: "6",
    },
    {
      label: t("exit.answers.question16.answer7"),
      value: "7",
    },
    {
      label: t("exit.answers.question16.answer8"),
      value: "8",
    },
    {
      label: t("exit.answers.question16.answer9"),
      value: "9",
    },
    {
      label: t("exit.answers.question16.answer10"),
      value: "10",
    },
    {
      label: t("exit.answers.question16.answer11"),
      value: "11",
    },
  ];

  useEffect(() => {
    async function fetchData() {
      const response = await getExitQuestionnaire();
      if (response.status !== 200) {
        redirectUserUnauthorized();
        return;
      }
      reset(response.data);
    }
    fetchData();
  }, [reset]);

  function redirectUserUnauthorized() {
    sessionStorage.removeItem("currentPraxis");
    enqueueSnackbar(t("error_msg.please_log_in_again"), {
      variant: "error",
      autoHideDuration: 4000,
    });
    navigate("/" + i18n.language);
  }

  const onSubmit = async (data) => {
    console.log(JSON.stringify(data));
    let response = await saveExitQuestionnaire(data);
    if (response.status !== 200) {
      if (response.status === 401) {
        redirectUserUnauthorized();
        return;
      }
      enqueueSnackbar("exit.error_msg", {
        variant: "error",
        autoHideDuration: 4000,
      });
      return;
    } else {
      enqueueSnackbar(t("exit.success_msg"), {
        variant: "success",
        autoHideDuration: 4000,
        onClose: () => {
          navigate("/" + i18n.language + "/ressourcen");
        },
      });
      dispatch({ type: "LOGIN_SUCCESS", payload: response.data });
      sessionStorage.setItem("currentPraxis", JSON.stringify(response.data));
    }
  };

  const isConfirmDisable =
    isSubmitting || authState.praxis.StatusId !== STATUS_ID;

  return (
    <>
      <div className="exit-survey-body box__body">
        <form className="exit-survey-form">
          <fieldset className="form-question-stack" disabled={isConfirmDisable}>
            <div className="exit-survey__question">
              <p className="exit-survey__question-label">
                {t("exit.questions.question1")}
              </p>
              <Controller
                name="Q1"
                defaultValue={defaultValues.Q1}
                control={control}
                render={({ field }) => <Rating {...field} />}
              />
              {errors.Q1 && <ErrorMessage error={errors.Q1?.message} />}
            </div>

            <div className="exit-survey__question">
              <p className="exit-survey__question-label">
                {t("exit.questions.question2")}
              </p>
              <Controller
                name="Q2"
                defaultValue={defaultValues.Q2}
                control={control}
                render={({ field }) => (
                  <RadioGroup aria-label="Q2" row {...field}>
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label={t("exit.answers.question2.answer1")}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={t("exit.answers.question2.answer2")}
                    />
                  </RadioGroup>
                )}
              />
              {errors.Q2 && <ErrorMessage error={errors.Q2?.message} />}
            </div>

            <div className="exit-survey__question">
              <p className="exit-survey__question-label">
                {t("exit.questions.question3")}
              </p>

              <Controller
                name="Q3"
                defaultValue={defaultValues.Q3}
                control={control}
                render={({ field }) => (
                  <RadioGroup aria-label="Q3" row {...field}>
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label={t("exit.answers.question3.answer1")}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={t("exit.answers.question3.answer2")}
                    />
                  </RadioGroup>
                )}
              />
              {errors.Q3 && <ErrorMessage error={errors.Q3?.message} />}

              <p>{t("exit.why")}</p>

              <Controller
                name="Q4"
                defaultValue={defaultValues.Q4}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("general.answer")}
                    multiline
                    rows={3}
                    fullWidth
                    {...field}
                  />
                )}
              />
              {errors.Q4 && <ErrorMessage error={errors.Q4?.message} />}
            </div>

            <div className="exit-survey__question">
              <p className="exit-survey__question-label">
                {t("exit.questions.question5")}
              </p>
              <Controller
                name="Q5"
                defaultValue={defaultValues.Q5}
                control={control}
                render={({ field }) => (
                  <RadioGroup aria-label="Q5" row {...field}>
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label={t("exit.answers.question5.answer1")}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={t("exit.answers.question5.answer2")}
                    />
                  </RadioGroup>
                )}
              />
              {errors.Q5 && <ErrorMessage error={errors.Q5?.message} />}
              <p>{t("exit.why")}</p>
              <Controller
                name="Q6"
                defaultValue={defaultValues.Q6}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("general.answer")}
                    multiline
                    rows={3}
                    fullWidth
                    {...field}
                  />
                )}
              />
              {errors.Q6 && <ErrorMessage error={errors.Q6?.message} />}
            </div>
            <div className="exit-survey__question">
              <p className="exit-survey__question-label">
                {t("exit.questions.question7")}
              </p>
              {errors.Q7 && <ErrorMessage error={errors.Q7?.message} />}
              <FormInputMultiCheckbox
                control={control}
                setValue={setValue}
                name={"Q7"}
                label={"Q7"}
                options={optionsQ7}
                defaultValue={defaultValues.Q7}
                watch={watch}
              />
              <Controller
                name="Q8"
                defaultValue={defaultValues.Q8}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("general.answer")}
                    multiline
                    disabled={
                      !(getValues("Q7") && getValues("Q7").includes("8"))
                    }
                    rows={3}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>

            <div className="exit-survey__question">
              <p className="exit-survey__question-label">
                {t("exit.questions.question9")}
              </p>
              {errors.Q9 && <ErrorMessage error={errors.Q9?.message} />}
              <FormInputMultiCheckbox
                control={control}
                setValue={setValue}
                name={"Q9"}
                label={"Q9"}
                options={optionsQ9}
                defaultValue={defaultValues.Q9}
                watch={watch}
              />
              <Controller
                name="Q10"
                defaultValue={defaultValues.Q10}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("general.answer")}
                    multiline
                    disabled={
                      !(getValues("Q9") && getValues("Q9").includes("5"))
                    }
                    rows={3}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>

            <div className="exit-survey__question">
              <p className="exit-survey__question-label">
                {t("exit.questions.question11")}
              </p>
              <Controller
                name="Q11"
                defaultValue={defaultValues.Q11}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("general.answer")}
                    multiline
                    rows={3}
                    fullWidth
                    {...field}
                  />
                )}
              />
              {errors.Q11 && <ErrorMessage error={errors.Q11?.message} />}
            </div>

            <div className="exit-survey__question">
              <p className="exit-survey__question-label">
                {t("exit.questions.question12")}
              </p>
              <Controller
                name="Q12"
                defaultValue={defaultValues.Q12}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("general.answer")}
                    multiline
                    rows={3}
                    fullWidth
                    {...field}
                  />
                )}
              />
              {errors.Q12 && <ErrorMessage error={errors.Q12?.message} />}
            </div>

            <div className="exit-survey__question">
              <p className="exit-survey__question-label">
                {t("exit.questions.question13")}
              </p>
              <Controller
                name="Q13"
                defaultValue={defaultValues.Q13}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("general.answer")}
                    multiline
                    rows={3}
                    fullWidth
                    {...field}
                  />
                )}
              />
              {errors.Q13 && <ErrorMessage error={errors.Q13?.message} />}
            </div>

            <div className="exit-survey__question">
              <p className="exit-survey__question-label">
                {t("exit.questions.question19")}
              </p>
              <Controller
                name="Q19"
                defaultValue={defaultValues.Q19}
                control={control}
                render={({ field }) => (
                  <Rating
                    {...field}
                    onChange={(e) => field.onChange(parseInt(e.target.value))}
                  />
                )}
              />
              {errors.Q19 && <ErrorMessage error={errors.Q19?.message} />}
            </div>

            <div className="exit-survey__question">
              <p className="exit-survey__question-label">
                {t("exit.questions.question14")}
              </p>
              <Controller
                name="Q14"
                defaultValue={defaultValues.Q14}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("general.answer")}
                    multiline
                    rows={3}
                    fullWidth
                    {...field}
                  />
                )}
              />
              {errors.Q14 && <ErrorMessage error={errors.Q14?.message} />}
            </div>

            <div className="exit-survey__question">
              <p className="exit-survey__question-label">
                {t("exit.questions.question15")}
              </p>
              <Controller
                name="Q15"
                defaultValue={defaultValues.Q15}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("general.answer")}
                    multiline
                    rows={3}
                    fullWidth
                    {...field}
                  />
                )}
              />
              {errors.Q15 && <ErrorMessage error={errors.Q15?.message} />}
            </div>

            <div className="exit-survey__question">
              <p className="exit-survey__question-label">
                {t("exit.questions.question16")}
              </p>
              {errors.Q16 && <ErrorMessage error={errors.Q16?.message} />}
              <FormInputMultiCheckbox
                control={control}
                setValue={setValue}
                name={"Q16"}
                label={"Q16"}
                options={optionsQ16}
                defaultValue={defaultValues.Q16}
                watch={watch}
              />
              <Controller
                name="Q17"
                defaultValue={defaultValues.Q17}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("general.answer")}
                    multiline
                    disabled={
                      !(getValues("Q16") && getValues("Q16").includes("11"))
                    }
                    rows={3}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>

            <div className="exit-survey__question">
              <p className="exit-survey__question-label">
                {t("exit.questions.question18")}
              </p>
              <Controller
                name="Q18"
                defaultValue={defaultValues.Q18}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("general.answer")}
                    multiline
                    rows={3}
                    fullWidth
                    {...field}
                  />
                )}
              />
              {errors.Q18 && <ErrorMessage error={errors.Q18?.message} />}
            </div>
          </fieldset>
        </form>
      </div>

      <div className="exit-footnote">
        <p>{t("exit.footnotes")}</p>
      </div>

      <div className="exit-footer box__footer">
        <Stack direction="row" spacing={2}>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="secondary"
            disabled={isConfirmDisable}
          >
            {t("general.confirm")}
          </Button>
        </Stack>
      </div>
    </>
  );
};

export default ExitForm;
