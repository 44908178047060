const praxis = sessionStorage.getItem("currentPraxis")
  ? JSON.parse(sessionStorage.getItem("currentPraxis"))
  : "";

export const initialState = {
  praxis: praxis,
  loading: false,
  errorMessage: null,
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true,
      };
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        praxis: action.payload,
        loading: false,
      };
    case "LOGOUT":
      return {
        ...initialState,
        praxis: "",
      };
    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };
    default:
        return initialState;
  }
};
