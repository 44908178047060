import React from "react";
import "./AdminForgotPassword.scss";
import HeaderAdminLogin from "../../../components/header/HeaderAdminLogin";
import Footer from "../../../components/footer/Footer";
import logo from "../../../assets/images/denck-dran-logo.svg";
import logoFR from "../../../assets/images/denck-dran-logo-fr.svg";
import logoIT from "../../../assets/images/denck-dran-logo-it.svg";
import AdminForgotPasswordForm from "./AdminForgotPasswordForm";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { HelmetProvider, Helmet } from "react-helmet-async";

const AdminForgotPassword = () => {
  const { t, i18n } = useTranslation();
  const logoSrc = i18n.language === "de" ? logo : i18n.language === "fr" ? logoFR : logoIT;

  return (
    <div className="forgot-password login">
      <div className="forgot-password-wrapper">
        <HelmetProvider>
          <Helmet>
            <title>{t("general.title")}</title>
          </Helmet>
        </HelmetProvider>
        <HeaderAdminLogin showPraxisAccess={false} />

        <Grid container className="form-grid-container">
          <Grid className="login-patient-col" item xs={0} md={6}>
            <div className="login-patient-bg"></div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="form-box-container">
              <div className="form-box">
                <div className="form-box__stack">
                  <div className="logo">
                    <img
                      src={logoSrc}
                      className="form-box__logo"
                      alt="Denck Dran"
                    />
                  </div>

                  <p className="text-center">{t("forgot_password.header")}</p>

                  <hr className="form-box__divider" />

                  <AdminForgotPasswordForm />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Footer />
      </div>
    </div>
  );
};

export default AdminForgotPassword;
