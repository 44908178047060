import React from "react";
import { Box, Stack, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProgressBar from "../../../components/progressbar/ProgressBar";

const legends = [
  {
    id: 1,
    label: "admin.dashboard.nb_diagnosed_patients",
    color: "primary",
  },
  {
    id: 2,
    label: "admin.dashboard.nb_tested_patients",
    color: "secondary",
  },
  {
    id: 3,
    label: "admin.dashboard.nb_risk_patients",
    color: "lightblue",
  },
];

const DashboardBaselineScreening = ({ baseline, screening }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        p: 2,
        borderRadius: "12px",
        height: 1,
        boxSizing: "border-box",
      }}
    >
      <Stack direction="column" spacing={{ xs: 2, sm: 4, xl: 6 }}>
        <div>
          <h2>{t("admin.dashboard.baseline")}</h2>
          <div className="progress">
            <ProgressBar
              percentage={baseline.DiagnosedPatients.Percentage}
              number={baseline.DiagnosedPatients.Nb}
              bgColor="var(--clr-orange)"
              zIndex="4"
            />
            <ProgressBar
              percentage={baseline.TestedPatients.Percentage}
              number={baseline.TestedPatients.Nb}
              bgColor="var(--clr-blue)"
              zIndex="3"
            />
            <ProgressBar
              isTotal={true}
              percentage={baseline.RiskPatients.Percentage}
              number={baseline.RiskPatients.Nb}
              bgColor="var(--clr-lightblue)"
              zIndex="2"
            />
          </div>
        </div>
        <div>
          <h2>{t("admin.dashboard.screening")}*</h2>
          <div className="progress">
            <ProgressBar
              zIndex="4"
              percentage={screening.DiagnosedPatients.Percentage}
              number={screening.DiagnosedPatients.Nb}
              bgColor="var(--clr-orange)"
            />
            <ProgressBar
              zIndex="3"
              percentage={screening.TestedPatients.Percentage}
              number={screening.TestedPatients.Nb}
              bgColor="var(--clr-blue)"
            />
            <ProgressBar
              isTotal={true}
              zIndex="2"
              percentage={screening.RiskPatients.Percentage}
              number={screening.RiskPatients.Nb}
              bgColor="var(--clr-lightblue)"
            />
          </div>
        </div>
        <div className="legend-baseline-screening">
          <Grid container spacing={2}>
            {legends.map((legend) => (
              <Grid item xs={4} key={legend.id}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <span
                    className={`legend__color legend__color--${legend.color}`}
                  ></span>
                  <span className="legend__label">{t(legend.label)}</span>
                </Stack>
              </Grid>
            ))}
          </Grid>
          <p>
            <em>{t("admin.dashboard.per_weeks")}</em>
          </p>
        </div>
      </Stack>
    </Box>
  );
};

export default DashboardBaselineScreening;
