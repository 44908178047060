import React, { useEffect, useState } from "react";
import "./Praxen.scss";
import { Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  getPraxen,
  getExportPraxen,
} from "../../../services/admin/admin.service";
import PraxenList from "./PraxenList";

const Praxen = () => {
  const [praxen, setPraxen] = useState([]);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const response = await getPraxen();
      if (response.status !== 200) {
        handleErrors(response.status);
        return;
      }
      setPraxen(response.data);
    }
    fetchData();
  }, []);

  const handleErrors = (status) => {
    if (status === 401) {
      redirectUserUnauthorized();
      return;
    } else {
      enqueueSnackbar(t("error_msg.error_contact_admin"), {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  };

  const redirectUserUnauthorized = () => {
    sessionStorage.removeItem("currentAdmin");
    enqueueSnackbar(t("error_msg.please_log_in_again"), {
      variant: "error",
      autoHideDuration: 4000,
      onClose: () => {
        navigate(`/${i18n.language}/admin`);
      },
    });
  };

  const goToNewPraxisForm = () => {
    navigate(`new`);
  };

  const exportPraxen = () => {
    const response = getExportPraxen();
    window.location.href = response;
    /* if (response.status !== 200) {
      handleErrors(response.status);
      return;
    } 
    let fileName = response.headers["content-disposition"]
      .split(";")
      .find((n) => n.includes("filename="))
      .replace("filename=", "")
      .trim();
    let url = window.URL.createObjectURL(new Blob([response.data]));
    saveAs(url, fileName); */
  };

  return (
    <div className="praxen box box--white">
      <div className="praxen-header box__header">
        <div>
          <h1>{t("admin.praxen.header")}</h1>
        </div>
        <div>
          <Stack direction="row" spacing={2}>
            <Button
              onClick={exportPraxen}
              variant="contained"
              color="secondary"
              startIcon={<DownloadIcon />}
            >
              {t("admin.praxen.export")}
            </Button>
            <Button
              onClick={goToNewPraxisForm}
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
            >
              {t("admin.praxen.new_praxis")}
            </Button>
          </Stack>
        </div>
      </div>
      <div className="praxen-body box__body">
        <PraxenList praxen={praxen} />
      </div>
    </div>
  );
};

export default Praxen;
