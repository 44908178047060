import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import DashboardBaselineScreening from "./DashboardBaselineScreening";

const DashboardStats = ({ stats }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12} xl={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className="dashboard-stat-box dashboard-stat-box--multiline dashboard-stat-box--gray">
              <div className="dashboard-stat-box--line">
                <span className="dashboard-stat-box__label">
                  {t("admin.dashboard.praxen_nb_program")}
                </span>
                <span className="dashboard-stat-box__result">
                  {stats.TotalPraxis}
                </span>
              </div>
              <div className="dashboard-stat-box--line">
                <span className="dashboard-stat-box__label">
                  {t("admin.dashboard.praxen_nb_completed_program")}
                </span>
                <span className="dashboard-stat-box__result">
                  {stats.TotalPraxisFinished}
                </span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="dashboard-stat-box dashboard-stat-box--multiline dashboard-stat-box--lightblue">
              <div className="dashboard-stat-box--line">
                <span className="dashboard-stat-box__label">
                  {t("admin.dashboard.nb_total_patients")}*
                </span>
                <span className="dashboard-stat-box__result">
                  {stats.PatientsAtRisksStats.TotalPatients}
                </span>
              </div>
              <div className="dashboard-stat-box--line">
                <span className="dashboard-stat-box__label">
                  {t("admin.dashboard.nb_risk_patients")}*
                </span>
                <span className="dashboard-stat-box__result">
                  {stats.PatientsAtRisksStats.TotalPatientsAtRisk}
                </span>
              </div>
              <div className="dashboard-stat-box--line">
                <span className="dashboard-stat-box__label">
                  {t("admin.dashboard.percentage_risk_patients")}*
                </span>
                <span className="dashboard-stat-box__result">
                  {stats.PatientsAtRisksStats.PercentagePatientsAtRisk}%
                </span>
              </div>
              <div className="dashboard-stat-box--ref">
                <em>{t("admin.dashboard.total_3_weeks")}</em>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="dashboard-stat-box dashboard-stat-box--column dashboard-stat-box--lightblue">
              <span className="dashboard-stat-box__label">
                {t("admin.dashboard.atrisk_patients_percent")}
              </span>
              <span className="dashboard-stat-box__result">
                {(stats.AverageChangeRiskPatients > 0 ? "+" : "") +
                  `${stats.AverageChangeRiskPatients}pp`}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="dashboard-stat-box dashboard-stat-box--column dashboard-stat-box--secondary">
              <span className="dashboard-stat-box__label">
                {t("admin.dashboard.tested_persons_percent")}
              </span>
              <span className="dashboard-stat-box__result">
                {(stats.AverageChangeTestedPatients > 0 ? "+" : "") +
                  `${stats.AverageChangeTestedPatients}pp`}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="dashboard-stat-box dashboard-stat-box--column dashboard-stat-box--primary">
              <span className="dashboard-stat-box__label">
                {t("admin.dashboard.ckd_diagnoses_percent")}
              </span>
              <span className="dashboard-stat-box__result">
                {(stats.AverageChangeDiagnosedPatients > 0 ? "+" : "") +
                  `${stats.AverageChangeDiagnosedPatients}pp`}
              </span>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12} xl={6}>
        <DashboardBaselineScreening
          baseline={stats.Baseline}
          screening={stats.Screening}
        />
      </Grid>
    </Grid>
  );
};

export default DashboardStats;
