import PropTypes from "prop-types";
import "./StatisticsBox.scss";
import { useTranslation } from "react-i18next";

const StatisticsBox = ({ label, value, color, secondaryText }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={`stat-box stat-box--${color}`}>
        <div className="stat-box--row">
          <span className="stat-box__label">{t(label)}</span>
          <span className="stat-box__value">
            {`${value > 0 ? "+" : ""} ${value}pp`}
          </span>
        </div>
        <div className="stat-box--row">
          <span className="stat-box__secondary-text">{secondaryText}</span>
        </div>
      </div>
    </>
  );
};

StatisticsBox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
  secondaryText: PropTypes.string,
};

export default StatisticsBox;
