import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  TextField,
  Stack,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { loginAdmin } from "../../../services/admin/azlogin.service";
import { useNavigate, Link } from "react-router-dom";
import { useAdmin } from "../../../store/admin";

const ErrorMessage = ({ errorMsg }) => {
  if (errorMsg) return <Alert severity="error">{errorMsg}</Alert>;
  return null;
};

const LoginForm = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { adminState, dispatch } = useAdmin();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const loggedInAdmin = sessionStorage.getItem("currentAdmin")
      ? JSON.parse(sessionStorage.getItem("currentAdmin"))
      : "";
    if (loggedInAdmin) adminRedirection(loggedInAdmin.Language);
  }, []);

  const onSubmit = async ({ email, password }) => {
    dispatch({ type: "REQUEST_LOGIN" });
    const response = await loginAdmin({
      email,
      password,
    });
    handleWsReponse(response);
  };

  const handleWsReponse = (response) => {
    if (response?.status !== 200) {
      if (response?.data) {
        dispatch({ type: "LOGIN_ERROR", error: t("error_msg.access_denied") });
        return;
      }
      dispatch({
        type: "LOGIN_ERROR",
        error: t("error_msg.error_contact_admin"),
      });
      return;
    }
    dispatch({ type: "LOGIN_SUCCESS", payload: response.data });
    sessionStorage.setItem("currentAdmin", JSON.stringify(response.data));
    adminRedirection(response.data.Language);
  };

  const adminRedirection = (lang) => {
    const adminLanguage = lang.toLowerCase();
    if (i18n.language !== adminLanguage) {
      i18n.changeLanguage(adminLanguage);
    }
    navigate("/" + lang.toLowerCase() + "/admin/dashboard");
  };

  adminRedirection.propTypes = {
    lang: PropTypes.string,
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" spacing={2}>
          {adminState.errorMessage && (
            <ErrorMessage errorMsg={adminState.errorMessage} />
          )}
          <div>
            <Controller
              name="email"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  error={Boolean(errors.email)}
                  type="email"
                  label="Email"
                  {...field}
                />
              )}
              rules={{
                required: t("error_msg.email_required"),
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t("error_msg.email_not_valid"),
                },
              }}
            />
            {errors.email && (
              <p className="helper-text helper-text--error">
                {errors.email.message}
              </p>
            )}
          </div>
          <div>
            <Controller
              name="password"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  error={Boolean(errors.password)}
                  type="password"
                  label={t("general.password")}
                  {...field}
                />
              )}
              rules={{ required: t("error_msg.password_required") }}
            />
            {errors.password && (
              <p className="helper-text helper-text--error">
                {errors.password.message}
              </p>
            )}
          </div>

          <div className="login__forgot">
            <Link className="login__forgot-link login__forgot-link-admin" to="forgotpassword">
              {t("login.forgot_password")}
            </Link>
          </div>

          <Button
            type="submit"
            variant="contained"
            aria-label="Anmelden"
            disabled={adminState.loading}
          >
            {t("login.log_in")}
          </Button>
        </Stack>
      </form>
      <Backdrop
        sx={{ marginTop: 0, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={adminState.loading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </>
  );
};

export default LoginForm;
