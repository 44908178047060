import React from "react";
import "./StatusQuo.scss";
import StatusQuoForm from "./StatusQuoForm";
import { useTranslation } from "react-i18next";


const StatusQuo = () => {
  const { t } = useTranslation();

  return (
    <div className="status-quo-view box box--white">
      <div className="status-quo-header box__header">
        <div>
          <h1>{t("status_quo.header")}</h1>
        </div>
      </div>

      <StatusQuoForm />
    </div>
  );
};

export default StatusQuo;
