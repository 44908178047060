import React, { useState } from "react";
import "./HeaderLogin.scss";
import { Button, IconButton, Stack } from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import { Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HeaderLogin = ({ showAZAccess = true }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  //useEffect(() => { document.title = t('general.title') }, [i18n.language]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const changeLanguage = (lng) => {
    window.location.replace("/" + lng);
  };
  const handleClickAZAccess = () => {
    navigate("/" + i18n.language + "/admin");
  };

  return (
    <header className="login-header login-header--fixed">
      <div className="login-header__content">
        <Stack direction="row" spacing={2}>
          {showAZAccess && (
            <Button
              onClick={handleClickAZAccess}
              variant="contained"
              color="secondary"
            >
              {t("header.az_access")}
            </Button>
          )}
          <IconButton
            className="language-switch-btn"
            variant="contained"
            color="primary"
            aria-label="Change language"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <TranslateIcon></TranslateIcon>
          </IconButton>
          <Menu
            id="language-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 1,
              sx: {
                width: 160,
              },
            }}
          >
            <MenuItem onClick={() => changeLanguage("de")}>
              {t("praxis_details.language_DE")}
            </MenuItem>
            <MenuItem onClick={() => changeLanguage("fr")}>
              {t("praxis_details.language_FR")}
            </MenuItem>
            <MenuItem onClick={() => changeLanguage("it")}>
              {t("praxis_details.language_IT")}
            </MenuItem>
          </Menu>
        </Stack>
      </div>
    </header>
  );
};

export default HeaderLogin;
