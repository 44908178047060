import React, { useState, useEffect } from "react";
import "./PraxisDetails.scss";
import { useTranslation } from "react-i18next";
import PraxisDetailsForm from "./PraxisDetailsForm";
import { getTerritories } from "../../../../services/admin/admin.service";

const PraxisDetails = ({ praxis }) => {
  const { t } = useTranslation();
  const [territories, setTerritories] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await getTerritories();
      if (response?.status !== 200) return;
      setTerritories(response.data);
    }
    fetchData();
  }, []);

  return praxis ? (
    <PraxisDetailsForm praxis={praxis} territories={territories} />
  ) : (
    <div>{t("general.loading")}</div>
  );
};

export default PraxisDetails;
