export const initialState = {
  screening: [],
  loading: true,
  error: null,
  submitting: false,
};

export const formReducer = (state, action) => {
  switch (action.type) {
    case "GET_SUCCESS":
      return {
        screening: action.payload,
        loading: false,
        error: null,
      };

    case "GET_ERROR":
      return {
        screening: [],
        loading: false,
        error: action.error,
      };

    case "ON_CHANGE":
      const inputName = action.payload.event.target.name;
      const inputValue = action.payload.event.target.value;
      return {
        screening: state.screening.map((screening) => {
          if (screening.RecordDate === action.payload.data.RecordDate) {
            return { ...screening, [inputName]: inputValue };
          } else {
            return screening;
          }
        }),
        loading: false,
        error: null,
      };

    case "ON_SUBMIT":
      return {
        ...state,
        submitting: true,
      };
    case "SUBMIT_COMPLETE":
      return {
        ...state,
        submitting: false,
      };
    default:
      return state;
  }
};
