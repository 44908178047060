import React, { useState } from "react";
import "./Resources.scss";
import ThumbeGFRCalculator from "../../../assets/images/thumbnails/eGFR-calculator.jpg";
import ThumbAglaDE from "../../../assets/images/thumbnails/agla.ch_de.jpg";
import ThumbAglaFR from "../../../assets/images/thumbnails/agla.ch_fr.jpg";
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const Tools = ({ t, i18n }) => {
  const [open, setOpen] = useState(false);
  const [href, setHref] = useState("");
  const isCurrentLang = i18n.language === "de";
  const toolsList = [
    {
      id: 1,
      title: "resources.tools.tool_1.text",
      image: ThumbeGFRCalculator,
      url: "resources.tools.tool_1.link",
    },
    {
      id: 2,
      title: "resources.tools.tool_2.text",
      image: isCurrentLang ? ThumbAglaDE : ThumbAglaFR,
      url: "resources.tools.tool_2.link",
    },
  ];

  const handleClickOpen = (e) => {
    e.preventDefault();
    setHref(e.target.href);
    setOpen(true);
  };

  const handleClose = (e, clickContinue) => {
    if (clickContinue) window.open(href, "_blank");
    setHref("");
    setOpen(false);
  };

  return (
    <div id="rechnerTools" className="resources-tools">
      <h2>{t("resources.tools.title")}</h2>

      <Grid container spacing={2}>
        {toolsList &&
          toolsList.map(({ id, title, image, url }) => (
            <Grid item lg={3} md={4} xs={12} key={id}>
              <Card elevation={3} sx={{ height: 1 }}>
                <CardMedia
                  component="img"
                  height="180"
                  image={image}
                  alt={t(title)}
                />
                <CardContent>
                  <p>{t(title)}</p>
                </CardContent>
                <CardActions>
                  <Button
                    href={t(url)}
                    onClick={handleClickOpen}
                    variant="contained"
                  >
                    {t("resources.visit_website")}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("resources.external_links.dialog_title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("resources.external_links.dialog_content")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t("general.cancel")}
          </Button>
          <Button variant="contained" onClick={(e) => handleClose(e, true)}>
            {t("general.continue")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Tools;
