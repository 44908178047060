import React from "react";
import "./Layout.scss";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Menu from "../menu/Menu";
import { Outlet } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import DenckdranStatistics from "./popup/DenckdranStatistics/DenckdranStatistics";

const Layout = () => {
  const { t } = useTranslation();
  return (
    <div className="layout">
      <HelmetProvider>
        <Helmet>
          <title>{t("general.title")}</title>
        </Helmet>
      </HelmetProvider>

      <Header />

      <Menu />

      <DenckdranStatistics />

      <main className="main">
        <div className="main-container">
          <div className="main-content">
            <Outlet />
          </div>
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default Layout;
