import React from "react";
import { Routes, Route } from "react-router-dom";

import LayoutAdmin from "../layout/LayoutAdmin";
import LoginAdmin from "../../views/Admin/Login/Login";
import Dashboard from "../../views/Admin/Dashboard/Dashboard";
import Praxen from "../../views/Admin/Praxen/Praxen";
import NeuePraxis from "../../views/Admin/NeuePraxis/NeuePraxis";
import Praxis from "../../views/Admin/Praxis/Praxis";
import AdminForgotPassword from "../../views/Admin/ForgotPassword/AdminForgotPassword";
import MyAccount from "../../views/Admin/MyAccount/MyAccount";
import AdminResetPassword from "../../views/Admin/ResetPassword/AdminResetPassword";

import Layout from "../layout/Layout";
import Login from "../../views/Praxis/Login/Login";
import DashboardPraxis from "../../views/Praxis/Dashboard/Dashboard";
import StatusQuo from "../../views/Praxis/StatusQuo/StatusQuo";
import Screening from "../../views/Praxis/Screening/Screening";
import Baseline from "../../views/Praxis/Baseline/Baseline";
import ExitFragebogen from "../../views/Praxis/ExitFragebogen/ExitFragebogen";
import Resources from "../../views/Praxis/Resources/Resources";
import PraxisDetails from "../../views/Praxis/PraxisDetails/PraxisDetails";
import ForgotPassword from "../../views/Praxis/ForgotPassword/ForgotPassword";
import Register from "../../views/Praxis/Register/Register";
import ResetPassword from "../../views/Praxis/ResetPassword/ResetPassword";
import NotFound from "../../views/NotFound";

import { RequireAuth, AuthProvider } from "../../store/auth";
import { RequireAuthAdmin, AdminProvider } from "../../store/admin";
import { useTranslation } from "react-i18next";

export const AppRoutes = () => {
  const { i18n } = useTranslation();
  const baseRouteUrl = `/${i18n.language}`;

  return (
    <>
      <AuthProvider>
        <Routes>
          <Route path={`${baseRouteUrl}/`} element={<Login />} />
          <Route
            path={`${baseRouteUrl}/forgotpassword`}
            element={<ForgotPassword />}
          />
          <Route
            path={`${baseRouteUrl}/register/:token`}
            element={<Register />}
          />
          <Route
            path={`${baseRouteUrl}/resetpassword/:token`}
            element={<ResetPassword />}
          />
          <Route
            element={
              <RequireAuth>
                <Layout />
              </RequireAuth>
            }
          >
            <Route
              path={`${baseRouteUrl}/dashboard`}
              element={<DashboardPraxis />}
            />
            <Route
              path={`${baseRouteUrl}/status-quo`}
              element={<StatusQuo />}
            />
            <Route path={`${baseRouteUrl}/baseline`} element={<Baseline />} />
            <Route path={`${baseRouteUrl}/screening`} element={<Screening />} />
            <Route
              path={`${baseRouteUrl}/exit-fragebogen`}
              element={<ExitFragebogen />}
            />
            <Route
              path={`${baseRouteUrl}/ressourcen`}
              element={<Resources />}
            />
            <Route
              path={`${baseRouteUrl}/praxis-details`}
              element={<PraxisDetails />}
            />
          </Route>
        </Routes>
      </AuthProvider>

      <AdminProvider>
        <Routes>
          <Route path={`${baseRouteUrl}/admin`} element={<LoginAdmin />} />
          <Route
            path={`${baseRouteUrl}/admin/forgotpassword`}
            element={<AdminForgotPassword />}
          />
          <Route
            path={`${baseRouteUrl}/admin/resetpassword/:token`}
            element={<AdminResetPassword />}
          />
          <Route
            element={
              <RequireAuthAdmin>
                <LayoutAdmin />
              </RequireAuthAdmin>
            }
          >
            <Route
              index
              path={`${baseRouteUrl}/admin/dashboard`}
              element={<Dashboard />}
            />
            <Route path={`${baseRouteUrl}/admin/praxen`} element={<Praxen />} />
            <Route
              path={`${baseRouteUrl}/admin/praxen/:praxisId`}
              element={<Praxis />}
            />
            <Route
              path={`${baseRouteUrl}/admin/praxen/new`}
              element={<NeuePraxis />}
            />
            <Route
              path={`${baseRouteUrl}/admin/myaccount`}
              element={<MyAccount />}
            />
          </Route>

          <Route path={`${baseRouteUrl}/admin/*`} element={<NotFound />} />
        </Routes>
      </AdminProvider>
    </>
  );
};
