import React from "react";
import { useTranslation } from "react-i18next";

/* export interface ValidationError {
    key: string;
    values?: { [key: string]: any };
}

export interface ErrorMessageProps {
    error?: ValidationError | string;
} */

export const ErrorMessage = ({ error }) => {
  const { t } = useTranslation();
  if (error === undefined) {
    return null;
  } else if (typeof error === "string") {
    return <div className="helper-text helper-text--error">{error}</div>;
  } else {
    const { key, values } = error;
    return (
      <div className="helper-text helper-text--error">{t(key, values)}</div>
    );
  }
};
