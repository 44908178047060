import axios from "axios";

const domain = process.env.REACT_APP_DOMAIN;
const baseURL = `${domain}/AZService/AZService.svc`;

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

function getPraxisStatistics(praxisId) {
  return instance
    .get("/GetPraxisStatistics", {
      params: { praxisId: praxisId },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function getPraxisStatusQuo(praxisId) {
  return instance
    .get("/GetPraxisStatusQuo", {
      params: { praxisId: praxisId },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function getPraxisExitQuestionnaire(praxisId) {
  return instance
    .get("/GetPraxisExitQuestionnaire", {
      params: { praxisId: praxisId },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function getPraxisRecords(praxisId) {
  return instance
    .get("/GetPraxisRecords", {
      params: { praxisId: praxisId },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function updatePraxis(praxis) {
  return instance
    .post("/UpdatePraxis", praxis)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function updatePraxisRecords(data) {
  return instance
    .post("/UpdatePraxisRecords", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

export {
  getPraxisStatistics,
  getPraxisStatusQuo,
  getPraxisExitQuestionnaire,
  getPraxisRecords,
  updatePraxis,
  updatePraxisRecords,
};
