import React, { useState, useEffect } from "react";
import stylescss from "./DenckdranStatistics.module.scss";
import { getStatistics } from "../../../../services/praxis/praxis.service";
import { useTranslation } from "react-i18next";
import { Stack, Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import iconBluthoch from "../../../../assets/images/icons/bluthochdruck-icon.svg";
import iconDiabete from "../../../../assets/images/icons/diabete-icon.svg";
import iconHerz from "../../../../assets/images/icons/herz-icon.svg";
import iconUrin from "../../../../assets/images/icons/urin-albumin-icon.svg";
import iconNierenfunktion from "../../../../assets/images/icons/nierenfunktion-icon.svg";
import arrowWhiteRight from "../../../../assets/images/icons/arrow-white-right.svg";
import arrowWhiteRounded from "../../../../assets/images/icons/arrow-white-rounded.svg";

const DenckdranStatistics = () => {
  const [open, setOpen] = useState(() => {
    return sessionStorage.getItem("viewStatsProgramPopup") ? false : true;
  });
  const [stats, setStats] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const response = await getStatistics();
      if (response?.status !== 200) return;
      setStats(response.data);
    }
    fetchData();
  }, []);

  const handleClose = () => {
    setOpen(false);
    sessionStorage.setItem("viewStatsProgramPopup", true);
  };

  return (
    <Modal open={open} onClose={handleClose} className={stylescss.modal}>
      <div className={stylescss.popupDialog}>
        <div className={stylescss.popup}>
          <div className={stylescss.content}>
            <button onClick={handleClose} className={stylescss.close}>
              &times;
            </button>

            <TotalPraxis stats={stats} />

            <PatientsAtRisk stats={stats} />

            <DiagnosedPatients stats={stats} />
          </div>
          <References />
        </div>
      </div>
    </Modal>
  );
};

export default DenckdranStatistics;

const TotalPraxis = ({ stats }) => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" spacing={2}>
      <p className={stylescss.text}>
        <span className={stylescss.textOrange}>
          {stats ? stats.TotalPraxis : "--"}
        </span>{" "}
        {t("denckdran_popup.participating_practices")}
      </p>
      <p className={stylescss.text}>
        <span className={stylescss.textOrange}>
          {stats ? stats.PatientsAtRisksStats.TotalPatients.toLocaleString('de-CH') : "--"}
        </span>
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: t("denckdran_popup.total_patients_consults"),
          }}
        ></span>
      </p>
    </Stack>
  );
};

const PatientsAtRisk = ({ stats }) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Stack direction="column" spacing={4}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack
            direction="row"
            spacing={2}
            alignItems="flex-start"
            sx={{ width: "387px" }}
          >
            <div className="patient__arrow-down">
              <img src={arrowWhiteRounded} alt="Arrow white rounded" />
            </div>
            <Box
              sx={{
                color: "white.main",
                fontSize: "1.875rem",
                fontWeight: "600",
                lineHeight: "1.1",
                textTransform: "uppercase",
              }}
            >
              {t("denckdran_popup.percentagePatientsAtRisk1")}{" "}
              <span className={stylescss.textOrange}>
                {stats
                  ? stats.PatientsAtRisksStats.PercentagePatientsAtRisk
                  : "--"}
                <small>%</small>
              </span>{" "}
              <br />
              <strong
                dangerouslySetInnerHTML={{
                  __html: t("denckdran_popup.percentagePatientsAtRisk3"),
                }}
              ></strong>
            </Box>
          </Stack>
          <div className="patient__arrow-right">
            <img src={arrowWhiteRight} alt="arrow white right" />
          </div>
          <Box
            sx={{
              bgcolor: "primary.main",
              padding: "0.5rem 1rem",
              color: "white.main",
              fontSize: "1rem",
              fontWeight: "bold",
              borderRadius: "0 1rem",
              minWidth: "410px",
            }}
          >
            <Stack direction="column" spacing={1}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <img
                  src={iconBluthoch}
                  className={stylescss.iconRounded}
                  alt="Bluthochdruck icon"
                />
                <span>{t("denckdran_popup.hypertension")}</span>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={2}>
                <img
                  src={iconDiabete}
                  className={stylescss.iconRounded}
                  alt="Diabetes icon"
                />
                <span>{t("denckdran_popup.diabetes")}</span>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={2}>
                <img
                  src={iconHerz}
                  className={stylescss.iconRounded}
                  alt="Herz icon"
                />
                <span>{t("denckdran_popup.cardiovascular_disease")}</span>
              </Stack>
            </Stack>
          </Box>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Box
            className={stylescss.text}
            sx={{
              color: "white.main",
              maxWidth: "387px",
            }}
            dangerouslySetInnerHTML={{
              __html: t("denckdran_popup.patients_at_risk"),
            }}
          ></Box>
          <div className="patient__arrow-right">
            <img src={arrowWhiteRight} alt="arrow white right" />
          </div>
          <Box
            sx={{
              bgcolor: "primary.main",
              padding: "0.5rem 0.875rem",
              color: "white.main",
              borderRadius: "0 1rem",
              fontSize: "1rem",
              maxWidth: "410px",
            }}
          >
            <Stack direction="column" spacing={1}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <img
                  src={iconNierenfunktion}
                  className={stylescss.iconRounded}
                  alt="Nierenfunktion icon"
                />
                <Stack spacing={0}>
                  <strong>{t("denckdran_popup.kidney_function")}</strong>
                  <span>{t("denckdran_popup.serum_creatinine")}</span>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={2}>
                <img
                  src={iconUrin}
                  className={stylescss.iconRounded}
                  alt="Urin-Albumin icon"
                />
                <Stack spacing={0}>
                  <strong>{t("denckdran_popup.kidney_damage")}</strong>
                  <span>{t("denckdran_popup.urine_creatinine_ratio")}</span>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

const DiagnosedPatients = ({ stats }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        maxWidth: "420px",
        mt: 4,
      }}
    >
      <p className={stylescss.text}>
        <span
          dangerouslySetInnerHTML={{
            __html: t("denckdran_popup.diagnosis_rate1", {
              baseline: stats
                ? stats.Baseline.DiagnosedPatients.Percentage
                : "--",
            }),
          }}
        ></span>{" "}
        <strong>
          <span
            className={stylescss.textOrange}
            dangerouslySetInnerHTML={{
              __html: t("denckdran_popup.diagnosis_rate2", {
                screening: stats
                  ? stats.Screening.DiagnosedPatients.Percentage
                  : "--",
              }),
            }}
          ></span>{" "}
          {t("denckdran_popup.increases")}
        </strong>
      </p>

      <p
        className={stylescss.smallText}
        dangerouslySetInnerHTML={{
          __html: t("denckdran_popup.proportion_newly_diagnosed"),
        }}
      ></p>
    </Box>
  );
};

const References = () => {
  const { t } = useTranslation();
  const datum = new Date().toLocaleDateString('de-CH');
  return (
    <div className={stylescss.references}>
      {t("denckdran_popup.reference", { datum: datum })}
    </div>
  );
};
