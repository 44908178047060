import React from "react";
import "./Screening.scss";
import ScreeningForm from "./ScreeningForm";
import { useTranslation } from "react-i18next";

const Screening = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="screening box box--white">
      <div className="screening-header box__header">
        <div>
          <h1>{t("screening.header")}</h1>
        </div>
      </div>
      <ScreeningForm t={t} i18n={i18n} />
    </div>
  );
};

export default Screening;
