import axios from "axios";

const domain = process.env.REACT_APP_DOMAIN;
const baseURL = `${domain}/PraxisService/PraxisService.svc`;

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

function getBaseline() {
  return instance
    .get(`/GetBaseline`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function saveBaseline(data) {
  return instance
    .post(`/SetBaseline`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function validateBaseline(data) {
  return instance
    .post(`/ValidateBaseline`, JSON.stringify(data))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

export { getBaseline, saveBaseline, validateBaseline };
