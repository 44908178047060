import axios from "axios";

const domain = process.env.REACT_APP_DOMAIN;
const baseURL = `${domain}/AZService/AZService.svc`;

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

function getPraxen() {
  return instance
    .get("/GetPraxen")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function getPraxis(praxisId) {
  return instance
    .get("/GetPraxis", {
      params: { praxisId: praxisId },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function getTerritories() {
  return instance
    .get("/GetTerritoriesList")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function getStatistics() {
  return instance
    .get("/GetStatistics")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function getExportPraxen() {
  return `${baseURL}/Export/ExportPraxen`;
  /* return axios
    .get(`${baseURL}/Export/ExportPraxen`, axiosConfig)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      return error.response;
    }); */
}

function getAlerts() {
  return instance
    .get("/GetAlerts")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function createPraxis(praxis) {
  return instance
    .post("/CreatePraxis", praxis)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function resendActivationEmail(praxisId) {
  return instance
    .post("/ResendActivationEmail", { PraxisId: praxisId })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function sendReminder(praxisId) {
  return instance
    .post("/SendReminder", { PraxisId: praxisId })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function changePassword(data) {
  return instance
    .post("/ChangePassword", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

export {
  getPraxen,
  getPraxis,
  getTerritories,
  getStatistics,
  getAlerts,
  getExportPraxen,
  createPraxis,
  resendActivationEmail,
  sendReminder,
  changePassword,
};
