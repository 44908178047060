import React from "react";
import AZlogo from "./astrazeneca-logo.svg";
import "./Footer.scss";
import { useTranslation } from "react-i18next";
import { Grid, Stack } from "@mui/material";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <Grid
        container
        columnSpacing={1}
        rowSpacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="footer-container"
      >
        <Grid item xl={4} lg={4} xs={12}>
          <Stack direction="column" spacing={1}>
            <div className="footer__disclaimer">
              <p>{t("footer.disclaimer")}</p>
            </div>
            <div className="footer__copyright">
              <p>{t("footer.copyright")}</p>
            </div>
          </Stack>
        </Grid>
        <Grid item xl={5} lg={4} xs={12}>
          <div className="footer__links">
            <a
              href={t("footer.terms_of_use_link")}
              rel="noreferrer"
              target="_blank"
            >
              {t("footer.terms_of_use")}
            </a>{" "}
            |{" "}
            <a
              href={t("footer.data_protection_link")}
              rel="noreferrer"
              target="_blank"
            >
              {t("footer.data_protection")}
            </a>{" "}
            |{" "}
            <a
              href={t("footer.cookie_policy_link")}
              className="CookieReportsLink"
            >
              {t("footer.cookie_policy")}
            </a>
          </div>
        </Grid>
        <Grid item xl={3} lg={4} xs={12}>
          <div className="footer__logo">
            <img src={AZlogo} alt="AstraZeneca" className="footer__logo-img" />
          </div>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
