import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const AnchorItem = ({ title, link, active }) => {
  const { t } = useTranslation();

  const [anchorTarget, setAnchorTarget] = useState(null);

  useEffect(() => {
    setAnchorTarget(document.getElementById(link));
  }, [link]);

  const handleClick = (event) => {
    event.preventDefault();
    anchorTarget.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <li className="anchor-item">
      <a
        href={`#${link}`}
        onClick={handleClick}
        className={active}
        aria-label={`Scroll to ${link}`}
      >
        {t(title)}
      </a>
    </li>
  );
};

export default AnchorItem;
