import "./App.scss";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/menu/ScrollToTop";
import useScript from "./hooks/useScript";
import { useTranslation } from "react-i18next";
import { SnackbarProvider } from "notistack";
import { AppRoutes } from "./components/routes/Routes";
import { setLocale } from "yup";
import { yupLocale } from "./utils";

setLocale(yupLocale);

function App() {
  const { i18n } = useTranslation();
  const cookieReportScript = `//policy.cookiereports.com/8e9c791b_panel-${(i18n.language==="it")?"it-ch":i18n.language}.js`;
  useScript(cookieReportScript);

  return (
    <SnackbarProvider maxSnack={3}>
      <BrowserRouter>
        <ScrollToTop>
          <AppRoutes />
        </ScrollToTop>
      </BrowserRouter>
    </SnackbarProvider>
  );
}

export default App;
