import axios from "axios";

const domain = process.env.REACT_APP_DOMAIN;
const baseURL = `${domain}/PraxisService/PraxisService.svc`;

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

function getScreening() {
  return instance
    .get(`/GetScreening`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function setScreening(data) {
  return instance
    .post(`/SetScreening`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function validateScreening(data) {
  return instance
    .post(`/ValidateScreening`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

export { getScreening, setScreening, validateScreening };
