import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, TextField, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { activatePraxis } from "../../../services/login.service";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ErrorMessage } from "../../../components/form";

const RegisterForm = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useParams();

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    newPassword: yup.string().required().min(8),
    confirmPassword: yup
      .string()
      .required()
      .oneOf(
        [yup.ref("newPassword")],
        t("form_validations.password_not_match")
      ),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async ({ email, newPassword }) => {
    if (email.length === 0 || newPassword.length === 0 || token.length === 0)
      return;

    const response = await activatePraxis({
      email,
      newPassword,
      token,
    });

    if (response.data) {
      enqueueSnackbar(t("register.success_msg"), {
        variant: "success",
        autoHideDuration: 4000,
        onClose: () => {
          navigate("/" + i18n.language);
        },
      });
    } else {
      enqueueSnackbar(t("register.error_msg"), {
        variant: "error",
        autoHideDuration: 4000,
      });
      return;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" spacing={2}>
          <div>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  error={Boolean(errors.email)}
                  type="email"
                  label="Email"
                  {...field}
                />
              )}
            />
            {errors.email && <ErrorMessage error={errors.email?.message} />}
          </div>

          <div>
            <Controller
              name="newPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  error={Boolean(errors.newPassword)}
                  type="password"
                  label={t("general.password")}
                  {...field}
                />
              )}
            />
            {errors.newPassword && (
              <ErrorMessage error={errors.newPassword?.message} />
            )}
          </div>

          <div>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  error={Boolean(errors.confirmPassword)}
                  type="password"
                  label={t("general.confirm_password")}
                  {...field}
                />
              )}
            />
            {errors.confirmPassword && (
              <ErrorMessage error={errors.confirmPassword?.message} />
            )}
          </div>

          <Button
            type="submit"
            variant="contained"
            aria-label="confirm"
            disabled={isSubmitting}
          >
            {t("register.registration")}
          </Button>

          <Link className="forgotpassword__back-link" to={"/" + i18n.language}>
            {t("forgot_password.back_login")}
          </Link>
        </Stack>
      </form>
    </>
  );
};

export default RegisterForm;
