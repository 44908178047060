import React from "react";
import "./Login.scss";
import HeaderLogin from "../../../components/header/HeaderLogin";
import Footer from "../../../components/footer/Footer";
import { Grid, Stack, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { HelmetProvider, Helmet } from "react-helmet-async";
import LoginForm from "./LoginForm";
import LoginInfoButtons from "./LoginInfoButtons";
import logo from "../../../assets/images/denck-dran-logo.svg";
import logoFR from "../../../assets/images/denck-dran-logo-fr.svg";
import logoIT from "../../../assets/images/denck-dran-logo-it.svg";
import iconBluthoch from "../../../assets/images/icons/bluthochdruck-icon.svg";
import iconDiabete from "../../../assets/images/icons/diabete-icon.svg";
import iconHerz from "../../../assets/images/icons/herz-icon.svg";
import iconUrin from "../../../assets/images/icons/urin-albumin-icon.svg";
import iconNierenfunktion from "../../../assets/images/icons/nierenfunktion-icon.svg";
import arrowWhiteRight from "../../../assets/images/icons/arrow-white-right.svg";
import arrowWhiteDown from "../../../assets/images/icons/arrow-white-down.svg";
import { LoginCarousel } from "./LoginCarousel/LoginCarousel";

const Login = () => {
  const { t } = useTranslation();

  return (
    <div className="login">
      <div className="login-wrapper">
        <HelmetProvider>
          <Helmet>
            <title>{t("general.title")}</title>
          </Helmet>
        </HelmetProvider>

        <HeaderLogin />

        <Grid container className="login-grid-container">
          <LoginLeftSide />
          <LoginRightSide />
        </Grid>

        <Footer />
      </div>
    </div>
  );
};

export default Login;

const LoginLeftSide = () => {
  const { i18n } = useTranslation();

  const logoSrc =
    i18n.language === "de" ? logo : i18n.language === "fr" ? logoFR : logoIT;
  return (
    <Grid item xs={12} lg={6} p={{ xs: 4, xl: 6 }}>
      <Stack
        direction="column"
        spacing={{ xs: 2, md: 4, xl: 8 }}
        justifyContent="space-around"
      >
        <div className="logo">
          <img src={logoSrc} className="login-logo__img" alt="Denck Dran" />
        </div>

        <LoginCarousel />

        <div className="login-box-container">
          <div className="login-box">
            <Grid
              container
              direction="row"
              columnSpacing={6}
              rowSpacing={{ xs: 4 }}
            >
              <Grid item xs={12} md={6}>
                <LoginForm />
              </Grid>

              <Grid item xs={12} md={6}>
                <LoginInfoButtons />
              </Grid>
            </Grid>
          </div>
        </div>
      </Stack>
    </Grid>
  );
};

const LoginRightSide = () => {
  const { t } = useTranslation();

  return (
    <Grid className="login-patient-col" item xs={12} lg={6}>
      <div className="login-patient-bg">
        <div className="login-patient__content">
          <Box
            sx={{
              maxWidth: "500px",
              color: "white.main",
              fontSize: "1.25rem",
              mb: 1,
            }}
          >
            <Stack direction="column" spacing={1}>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("login.the_program"),
                }}
              ></span>

              <span>
                <strong>{t("login.denckdran_participant")}</strong>
              </span>
            </Stack>
          </Box>
          <Stack direction="row" spacing={1} alignItems="stretch">
            <div>
              <Stack direction="row" spacing={1} alignItems="flex-start">
                <div className="patient__arrow-down">
                  <img src={arrowWhiteDown} alt="Arrow white down" />
                </div>
                <Box
                  sx={{
                    color: "white.main",
                    maxWidth: "150px",
                    pt: 8,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: t("login.the_program_supports"),
                  }}
                ></Box>
              </Stack>
            </div>
            <div>
              <Stack direction="column" spacing={4}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <div className="patient__arrow-right">
                    <img src={arrowWhiteRight} alt="arrow white right" />
                  </div>
                  <Box
                    sx={{
                      bgcolor: "primary.main",
                      padding: "0.5rem 1rem",
                      color: "white.main",
                      fontSize: "0.75rem",
                      fontWeight: "bold",
                      borderRadius: "0 1rem",
                      minWidth: "310px",
                    }}
                  >
                    <Stack direction="column" spacing={1}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <img src={iconBluthoch} alt="Bluthochdruck icon" />
                        <span>{t("login.hypertension")}</span>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <img src={iconDiabete} alt="Diabetes icon" />
                        <span>{t("login.diabetes")}</span>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <img src={iconHerz} alt="Herz icon" />
                        <span>{t("login.cardiovascular_disease")}</span>
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <div className="patient__arrow-right">
                    <img src={arrowWhiteRight} alt="arrow white right" />
                  </div>
                  <Box
                    sx={{
                      bgcolor: "primary.main",
                      padding: "0.5rem 0.875rem",
                      color: "white.main",
                      borderRadius: "0 1rem",
                      fontSize: "0.75rem",
                      maxWidth: "310px",
                    }}
                  >
                    <Stack direction="column" spacing={1}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <img
                          src={iconNierenfunktion}
                          alt="Nierenfunktion icon"
                        />
                        <Stack spacing={0}>
                          <strong>{t("login.kidney_function")}</strong>
                          <span>{t("login.serum_creatinine")}</span>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <img src={iconUrin} alt="Urin-Albumin icon" />
                        <Stack spacing={0}>
                          <strong>{t("login.kidney_damage")}</strong>
                          <span>{t("login.urine_creatinine_ratio")}</span>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
              </Stack>
            </div>
          </Stack>
        </div>

        <Box
          sx={{
            color: "white.main",
            fontSize: "0.625rem",
            width: "100%",
            bgcolor: "secondary.main",
            px: 1.5,
            py: 1,
          }}
        >
          <span>{t("login.references")}</span>
        </Box>
      </div>
    </Grid>
  );
};
