import React, { useEffect } from "react";
import "./PraxisDetails.scss";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { ErrorMessage } from "../../../../components/form";
import {
  Button,
  Grid,
  TextField,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  FormLabel,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { updatePraxis } from "../../../../services/admin/praxis.service";
import moment from "moment";

const STATUS_ID = 3;

const PraxisDetailsForm = ({ praxis, territories }) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();

  const schema = yup.object({
    PraxisName: yup.string().required(),
    PraxisAddress: yup.string().nullable(),
    PraxisZipCode: yup.string().nullable(),
    PraxisCity: yup.string().nullable(),
    Language: yup.string().required(),
    ContactGender: yup.string().nullable(),
    ContactFirstName: yup.string().nullable(),
    ContactLastName: yup.string().nullable(),
    ContactPhone: yup.string().nullable(),
    Email: yup.string().email().required(),
    StartDate: yup.date().required(),
    TerritoryId: yup.number().required(),
  });

  const isDECurrentLang = i18n.language === "de";
  const isFRCurrentLang = i18n.language === "fr";

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    reset(praxis);
  }, [reset]);

  const onSubmit = async (formData) => {
    const startDateWithoutUTC = moment(formData.StartDate)
      .utcOffset(0, true)
      .format();
    formData.StartDate = startDateWithoutUTC;
    const response = await updatePraxis(formData);
    handleResponse(response);
  };

  const handleResponse = (response) => {
    if (response?.status !== 200) {
      handleErrors(response.status);
      return;
    }

    enqueueSnackbar(t("praxis_details.praxis_success_msg"), {
      variant: "success",
      autoHideDuration: 4000,
    });
  };

  const handleErrors = (status) => {
    switch (status) {
      case 401:
        redirectUserUnauthorized();
        break;
      case 409:
        enqueueSnackbar(t("error_msg.email_already_used"), {
          variant: "error",
          autoHideDuration: 4000,
        });
        break;
      case 500:
        enqueueSnackbar(t("error_msg.error_contact_admin"), {
          variant: "error",
          autoHideDuration: 4000,
        });
        break;
      default:
        enqueueSnackbar(t("error_msg.error_contact_admin"), {
          variant: "error",
          autoHideDuration: 4000,
        });
    }
  };

  const redirectUserUnauthorized = () => {
    sessionStorage.removeItem("currentAdmin");
    enqueueSnackbar(t("error_msg.please_log_in_again"), {
      variant: "error",
      autoHideDuration: 4000,
      onClose: () => {
        navigate(`/${i18n.language}/admin`);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className="form-praxis-details__stack" disabled={isSubmitting}>
        <div>
          <h3>{t("praxis_details.info")}</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6} xl={6}>
              <Controller
                name="PraxisName"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    type="text"
                    label={t("praxis_details.praxis_name")}
                    fullWidth
                    required
                    {...field}
                  />
                )}
              />
              {errors.PraxisName && (
                <ErrorMessage error={errors.PraxisName?.message} />
              )}
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
              <Controller
                name="PraxisAddress"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    type="text"
                    label={t("praxis_details.praxis_address")}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={3} xl={3}>
              <Controller
                name="PraxisZipCode"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    type="text"
                    label={t("praxis_details.praxis_zip_code")}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={3} xl={3}>
              <Controller
                name="PraxisCity"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    type="text"
                    label={t("praxis_details.praxis_city")}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
              <FormControl required sx={{ width: 1 }}>
                <InputLabel id="territory-label">
                  {t("admin.general.territory")}
                </InputLabel>
                <Controller
                  name="TerritoryId"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <Select
                      label={t("admin.general.territory")}
                      labelId="territory-label"
                      {...field}
                    >
                      {territories &&
                        territories.map((territory) => {
                          return (
                            <MenuItem
                              key={territory.TerritoryId}
                              value={territory.TerritoryId}
                            >
                              {isDECurrentLang
                                ? territory.TerritoryNameDE
                                : isFRCurrentLang
                                ? territory.TerritoryNameFR
                                : territory.TerritoryNameIT}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.TerritoryId && (
                <ErrorMessage error={errors.TerritoryId?.message} />
              )}
            </Grid>
          </Grid>
        </div>
        <div>
          <h3>{t("praxis_details.contact")}</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6} xl={6}>
              <FormLabel component="legend">
                {t("praxis_details.gender")}:
              </FormLabel>
              <Controller
                name="ContactGender"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <RadioGroup aria-label="gender" row {...field}>
                    <FormControlLabel
                      value="frau"
                      control={<Radio />}
                      label={t("praxis_details.gender_values.gender_frau")}
                    />
                    <FormControlLabel
                      value="mann"
                      control={<Radio />}
                      label={t("praxis_details.gender_values.gender_mann")}
                    />
                    <FormControlLabel
                      value="divers"
                      control={<Radio />}
                      label={t("praxis_details.gender_values.gender_divers")}
                    />
                  </RadioGroup>
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
              <FormLabel component="legend">
                {t("praxis_details.language")}: *
              </FormLabel>
              <Controller
                name="Language"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <RadioGroup aria-label="sprache" row {...field}>
                    <FormControlLabel
                      value="DE"
                      control={<Radio />}
                      label={t("praxis_details.language_DE")}
                    />
                    <FormControlLabel
                      value="FR"
                      control={<Radio />}
                      label={t("praxis_details.language_FR")}
                    />
                    <FormControlLabel
                      value="IT"
                      control={<Radio />}
                      label={t("praxis_details.language_IT")}
                    />
                  </RadioGroup>
                )}
              />
              {errors.Language && (
                <ErrorMessage error={errors.Language?.message} />
              )}
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
              <Controller
                name="ContactFirstName"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    type="text"
                    label={t("praxis_details.first_name")}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
              <Controller
                name="ContactLastName"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    type="text"
                    label={t("praxis_details.last_name")}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
              <Controller
                name="ContactPhone"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    type="text"
                    label={t("praxis_details.phone_number")}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
              <Controller
                name="Email"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    type="email"
                    label={t("praxis_details.email")}
                    fullWidth
                    required
                    {...field}
                  />
                )}
              />
              {errors.Email && <ErrorMessage error={errors.Email?.message} />}
            </Grid>
          </Grid>
        </div>
        <div>
          <h3>{t("admin.neue_praxis.screening_startdate")} *</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name="StartDate"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    type="date"
                    disabled={praxis.StatusId > STATUS_ID}
                    required
                    fullWidth
                    {...field}
                  />
                )}
              />
              {errors.StartDate && (
                <ErrorMessage error={errors.StartDate?.message} />
              )}
            </Grid>
          </Grid>
        </div>
        <div className="form-praxis-details__actions">
          <Button type="submit" variant="contained" color="secondary">
            {t("general.update")}
          </Button>
        </div>
      </fieldset>
    </form>
  );
};

export default PraxisDetailsForm;
