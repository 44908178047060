import React, { useEffect } from "react";
import {
  TextField,
  Rating,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { FormInputMultiCheckbox } from "../../../../components/form";

const defaultValues = {
  Q1: 0,
  Q2: "",
  Q3: "",
  Q4: "",
  Q5: "",
  Q6: "",
  Q7: 0,
  Q8: "",
  Q9: "",
  Q10: "",
  Q11: [],
};

const isReadOnly = true;

const PraxisStatusQuoForm = ({ statusQuo }) => {
  const { t } = useTranslation();
  const schema = yup.object({
    Q1: yup
      .number()
      .min(1, t("error_msg.mandatory_field"))
      .required(t("error_msg.mandatory_field")),
    Q2: yup.string().required(t("error_msg.mandatory_field")),
    Q3: yup.string().required(t("error_msg.mandatory_field")),
    Q4: yup.string().required(t("error_msg.mandatory_field")),
    Q5: yup.string().required(t("error_msg.mandatory_field")),
    Q6: yup.string().required(t("error_msg.mandatory_field")),
    Q7: yup
      .number()
      .min(1, t("error_msg.mandatory_field"))
      .required(t("error_msg.mandatory_field")),
    Q8: yup.string().required(t("error_msg.mandatory_field")),
    Q9: yup.string().required(t("error_msg.mandatory_field")),
    Q10: yup.string().required(t("error_msg.mandatory_field")),
    Q11: yup.array().min(1).required(t("error_msg.mandatory_field")),
  });
  const { control, setValue, reset, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const options = [
    {
      label: t("status_quo.answers.question11.answer1"),
      value: "1",
    },
    {
      label: t("status_quo.answers.question11.answer2"),
      value: "2",
    },
    {
      label: t("status_quo.answers.question11.answer3"),
      value: "3",
    },
    {
      label: t("status_quo.answers.question11.answer4"),
      value: "4",
    },
    {
      label: t("status_quo.answers.question11.answer5"),
      value: "5",
    },
    {
      label: t("status_quo.answers.question11.answer6"),
      value: "6",
    },
    {
      label: t("status_quo.answers.question11.answer7"),
      value: "7",
    },
    {
      label: t("status_quo.answers.question11.answer8"),
      value: "8",
    },
    {
      label: t("status_quo.answers.question11.answer9"),
      value: "9",
    },
    {
      label: t("status_quo.answers.question11.answer10"),
      value: "10",
    },
  ];

  useEffect(() => {
    reset(statusQuo);
  }, [reset]);

  return (
    <>
      <form>
        <fieldset disabled={isReadOnly}>
          <div className="status-quo-form">
            <div className="status-quo__question">
              <p className="status-quo__question-text">
                {t("status_quo.questions.question1")}
              </p>
              <Controller
                name="Q1"
                defaultValue={defaultValues.Q1}
                control={control}
                render={({ field }) => {
                  return (
                    <Rating
                      {...field}
                      onChange={(e) => field.onChange(parseInt(e.target.value))}
                    />
                  );
                }}
              />
            </div>
            <div className="status-quo__question">
              <p className="status-quo__question-text">
                {t("status_quo.questions.question2")}
              </p>
              <Controller
                name="Q2"
                defaultValue={defaultValues.Q2}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("general.answer")}
                    multiline
                    rows={3}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>
            <div className="status-quo__question">
              <p className="status-quo__question-text">
                {t("status_quo.questions.question3")}
              </p>
              <Controller
                name="Q3"
                defaultValue={defaultValues.Q3}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("general.answer")}
                    multiline
                    rows={3}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>
            <div className="status-quo__question">
              <p className="status-quo__question-text">
                {t("status_quo.questions.question4")}
              </p>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Controller
                    name="Q4"
                    defaultValue={defaultValues.Q4}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label={t("status_quo.questions.question4_a")}
                        multiline
                        rows={3}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Controller
                    name="Q5"
                    defaultValue={defaultValues.Q5}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label={t("status_quo.questions.question4_b")}
                        multiline
                        rows={3}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="status-quo__question">
              <p className="status-quo__question-text">
                {t("status_quo.questions.question6")}
              </p>
              <Controller
                name="Q6"
                defaultValue={defaultValues.Q6}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("general.answer")}
                    multiline
                    rows={3}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>
            <div className="status-quo__question">
              <p className="status-quo__question-text">
                {t("status_quo.questions.question7")}
              </p>
              <Controller
                name="Q7"
                defaultValue={defaultValues.Q7}
                control={control}
                render={({ field }) => {
                  return (
                    <Rating
                      {...field}
                      onChange={(e) => field.onChange(parseInt(e.target.value))}
                    />
                  );
                }}
              />
            </div>
            <div className="status-quo__question">
              <p className="status-quo__question-text">
                {t("status_quo.questions.question8")}
              </p>
              <Controller
                name="Q8"
                defaultValue={defaultValues.Q8}
                control={control}
                render={({ field }) => (
                  <RadioGroup aria-label="Q8" row {...field}>
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label={t("status_quo.answers.question8.answer1")}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={t("status_quo.answers.question8.answer2")}
                    />
                  </RadioGroup>
                )}
              />
            </div>
            <div className="status-quo__question">
              <p className="status-quo__question-text">
                {t("status_quo.questions.question9")}
              </p>
              <Controller
                name="Q9"
                defaultValue={defaultValues.Q9}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("general.answer")}
                    multiline
                    rows={3}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>
            <div className="status-quo__question">
              <p className="status-quo__question-text">
                {t("status_quo.questions.question10")}
              </p>
              <Controller
                name="Q10"
                defaultValue={defaultValues.Q10}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("general.answer")}
                    multiline
                    rows={3}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>
            <div className="status-quo__question">
              <p className="status-quo__question-text">
                {t("status_quo.questions.question11")}
              </p>

              <FormInputMultiCheckbox
                control={control}
                setValue={setValue}
                name={"Q11"}
                label={"Q11"}
                options={options}
                watch={watch}
              />
            </div>
          </div>
        </fieldset>
      </form>
    </>
  );
};

export default PraxisStatusQuoForm;
