import React from "react";
import "./Baseline.scss";
import BaselineForm from "./BaselineForm";
import { useTranslation } from "react-i18next";

const Baseline = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="baseline box box--white">
      <div className="baseline-header box__header">
        <div>
          <h1>{t("baseline.header")}</h1>
        </div>
      </div>

      <BaselineForm t={t} i18n={i18n} />
    </div>
  );
};

export default Baseline;
