import React, { useEffect } from "react";
import {
  TextField,
  Rating,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInputMultiCheckbox } from "../../../../components/form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const defaultValues = {
  Q1: 0,
  Q2: "",
  Q3: "",
  Q4: "",
  Q5: "",
  Q6: "",
  Q7: [],
  Q8: "",
  Q9: [],
  Q10: "",
  Q11: "",
  Q12: "",
  Q13: "",
  Q14: "",
  Q15: "",
  Q16: [],
  Q17: "",
  Q18: "",
};

const isReadOnly = true;

const PraxisExitForm = ({ exitFormData }) => {
  const { t } = useTranslation();
  const schema = yup.object({
    Q1: yup
      .number()
      .min(1, t("error_msg.mandatory_field"))
      .required(t("error_msg.mandatory_field")),
    Q2: yup.string().required(t("error_msg.mandatory_field")),
    Q3: yup.string().required(t("error_msg.mandatory_field")),
    Q4: yup.string().required(t("error_msg.mandatory_field")),
    Q5: yup.string().required(t("error_msg.mandatory_field")),
    Q6: yup.string().required(t("error_msg.mandatory_field")),
    Q7: yup.array().min(1).required(t("error_msg.mandatory_field")),
    Q8: yup.string().notRequired(),
    Q9: yup.array().min(1).required(t("error_msg.mandatory_field")),
    Q10: yup.string().notRequired(),
    Q11: yup.string().required(t("error_msg.mandatory_field")),
    Q12: yup.string().required(t("error_msg.mandatory_field")),
    Q13: yup.string().required(t("error_msg.mandatory_field")),
    Q14: yup.string().required(t("error_msg.mandatory_field")),
    Q15: yup.string().required(t("error_msg.mandatory_field")),
    Q16: yup.array().min(1).required(t("error_msg.mandatory_field")),
    Q17: yup.string().notRequired(),
    Q18: yup.string().required(t("error_msg.mandatory_field")),
  });
  const { control, setValue, reset, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const optionsQ7 = [
    {
      label: t("exit.answers.question7.answer1"),
      value: "1",
    },
    {
      label: t("exit.answers.question7.answer2"),
      value: "2",
    },
    {
      label: t("exit.answers.question7.answer3"),
      value: "3",
    },
    {
      label: t("exit.answers.question7.answer4"),
      value: "4",
    },
    {
      label: t("exit.answers.question7.answer5"),
      value: "5",
    },
    {
      label: t("exit.answers.question7.answer6"),
      value: "6",
    },
    {
      label: t("exit.answers.question7.answer7"),
      value: "7",
    },
    {
      label: t("exit.answers.question7.answer8"),
      value: "8",
    },
  ];

  const optionsQ9 = [
    {
      label: t("exit.answers.question9.answer1"),
      value: "1",
    },
    {
      label: t("exit.answers.question9.answer2"),
      value: "2",
    },
    {
      label: t("exit.answers.question9.answer3"),
      value: "3",
    },
    {
      label: t("exit.answers.question9.answer4"),
      value: "4",
    },
    {
      label: t("exit.answers.question9.answer5"),
      value: "5",
    },
  ];

  const optionsQ16 = [
    {
      label: t("exit.answers.question16.answer1"),
      value: "1",
    },
    {
      label: t("exit.answers.question16.answer2"),
      value: "2",
    },
    {
      label: t("exit.answers.question16.answer3"),
      value: "3",
    },
    {
      label: t("exit.answers.question16.answer4"),
      value: "4",
    },
    {
      label: t("exit.answers.question16.answer5"),
      value: "5",
    },
    {
      label: t("exit.answers.question16.answer6"),
      value: "6",
    },
    {
      label: t("exit.answers.question16.answer7"),
      value: "7",
    },
    {
      label: t("exit.answers.question16.answer8"),
      value: "8",
    },
    {
      label: t("exit.answers.question16.answer9"),
      value: "9",
    },
    {
      label: t("exit.answers.question16.answer10"),
      value: "10",
    },
    {
      label: t("exit.answers.question16.answer11"),
      value: "11",
    },
  ];

  useEffect(() => {
    reset(exitFormData);
  }, [reset]);

  return (
    <form className="exit-survey-form">
      <fieldset className="form-question-stack" disabled={isReadOnly}>
        <div className="exit-survey__question">
          <p className="exit-survey__question-label">
            {t("exit.questions.question1")}
          </p>
          <Controller
            name="Q1"
            defaultValue={defaultValues.Q1}
            control={control}
            render={({ field }) => <Rating {...field} />}
          />
        </div>

        <div className="exit-survey__question">
          <p className="exit-survey__question-label">
            {t("exit.questions.question2")}
          </p>
          <Controller
            name="Q2"
            defaultValue={defaultValues.Q2}
            control={control}
            render={({ field }) => (
              <RadioGroup aria-label="Q2" row {...field}>
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={t("exit.answers.question2.answer1")}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={t("exit.answers.question2.answer2")}
                />
              </RadioGroup>
            )}
          />
        </div>

        <div className="exit-survey__question">
          <p className="exit-survey__question-label">
            {t("exit.questions.question3")}
          </p>

          <Controller
            name="Q3"
            defaultValue={defaultValues.Q3}
            control={control}
            render={({ field }) => (
              <RadioGroup aria-label="Q3" row {...field}>
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={t("exit.answers.question3.answer1")}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={t("exit.answers.question3.answer2")}
                />
              </RadioGroup>
            )}
          />

          <p>{t("exit.why")}</p>

          <Controller
            name="Q4"
            defaultValue={defaultValues.Q4}
            control={control}
            render={({ field }) => (
              <TextField
                label={t("general.answer")}
                multiline
                rows={3}
                fullWidth
                {...field}
              />
            )}
          />
        </div>

        <div className="exit-survey__question">
          <p className="exit-survey__question-label">
            {t("exit.questions.question5")}
          </p>
          <Controller
            name="Q5"
            defaultValue={defaultValues.Q5}
            control={control}
            render={({ field }) => (
              <RadioGroup aria-label="Q5" row {...field}>
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={t("exit.answers.question5.answer1")}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={t("exit.answers.question5.answer2")}
                />
              </RadioGroup>
            )}
          />
          <p>{t("exit.why")}</p>
          <Controller
            name="Q6"
            defaultValue={defaultValues.Q6}
            control={control}
            render={({ field }) => (
              <TextField
                label={t("general.answer")}
                multiline
                rows={3}
                fullWidth
                {...field}
              />
            )}
          />
        </div>
        <div className="exit-survey__question">
          <p className="exit-survey__question-label">
            {t("exit.questions.question7")}
          </p>

          <FormInputMultiCheckbox
            control={control}
            setValue={setValue}
            name={"Q7"}
            label={"Q7"}
            options={optionsQ7}
            defaultValue={defaultValues.Q7}
            watch={watch}
          />
          <Controller
            name="Q8"
            defaultValue={defaultValues.Q8}
            control={control}
            render={({ field }) => (
              <TextField
                label={t("general.answer")}
                multiline
                rows={3}
                fullWidth
                {...field}
              />
            )}
          />
        </div>

        <div className="exit-survey__question">
          <p className="exit-survey__question-label">
            {t("exit.questions.question9")}
          </p>

          <FormInputMultiCheckbox
            control={control}
            setValue={setValue}
            name={"Q9"}
            label={"Q9"}
            options={optionsQ9}
            defaultValue={defaultValues.Q9}
            watch={watch}
          />
          <Controller
            name="Q10"
            defaultValue={defaultValues.Q10}
            control={control}
            render={({ field }) => (
              <TextField
                label={t("general.answer")}
                multiline
                rows={3}
                fullWidth
                {...field}
              />
            )}
          />
        </div>

        <div className="exit-survey__question">
          <p className="exit-survey__question-label">
            {t("exit.questions.question11")}
          </p>
          <Controller
            name="Q11"
            defaultValue={defaultValues.Q11}
            control={control}
            render={({ field }) => (
              <TextField
                label={t("general.answer")}
                multiline
                rows={3}
                fullWidth
                {...field}
              />
            )}
          />
        </div>

        <div className="exit-survey__question">
          <p className="exit-survey__question-label">
            {t("exit.questions.question12")}
          </p>
          <Controller
            name="Q12"
            defaultValue={defaultValues.Q12}
            control={control}
            render={({ field }) => (
              <TextField
                label={t("general.answer")}
                multiline
                rows={3}
                fullWidth
                {...field}
              />
            )}
          />
        </div>

        <div className="exit-survey__question">
          <p className="exit-survey__question-label">
            {t("exit.questions.question13")}
          </p>
          <Controller
            name="Q13"
            defaultValue={defaultValues.Q13}
            control={control}
            render={({ field }) => (
              <TextField
                label={t("general.answer")}
                multiline
                rows={3}
                fullWidth
                {...field}
              />
            )}
          />
        </div>

        <div className="exit-survey__question">
          <p className="exit-survey__question-label">
            {t("exit.questions.question14")}
          </p>
          <Controller
            name="Q14"
            defaultValue={defaultValues.Q14}
            control={control}
            render={({ field }) => (
              <TextField
                label={t("general.answer")}
                multiline
                rows={3}
                fullWidth
                {...field}
              />
            )}
          />
        </div>

        <div className="exit-survey__question">
          <p className="exit-survey__question-label">
            {t("exit.questions.question15")}
          </p>
          <Controller
            name="Q15"
            defaultValue={defaultValues.Q15}
            control={control}
            render={({ field }) => (
              <TextField
                label={t("general.answer")}
                multiline
                rows={3}
                fullWidth
                {...field}
              />
            )}
          />
        </div>

        <div className="exit-survey__question">
          <p className="exit-survey__question-label">
            {t("exit.questions.question16")}
          </p>

          <FormInputMultiCheckbox
            control={control}
            setValue={setValue}
            name={"Q16"}
            label={"Q16"}
            options={optionsQ16}
            defaultValue={defaultValues.Q16}
            watch={watch}
          />
          <Controller
            name="Q17"
            defaultValue={defaultValues.Q17}
            control={control}
            render={({ field }) => (
              <TextField
                label={t("general.answer")}
                multiline
                rows={3}
                fullWidth
                {...field}
              />
            )}
          />
        </div>

        <div className="exit-survey__question">
          <p className="exit-survey__question-label">
            {t("exit.questions.question18")}
          </p>
          <Controller
            name="Q18"
            defaultValue={defaultValues.Q18}
            control={control}
            render={({ field }) => (
              <TextField
                label={t("general.answer")}
                multiline
                rows={3}
                fullWidth
                {...field}
              />
            )}
          />
        </div>
      </fieldset>
    </form>
  );
};

export default PraxisExitForm;
