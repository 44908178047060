import React from "react";
import adminlogin from "./Login.module.scss";
import HeaderAdminLogin from "../../../components/header/HeaderAdminLogin";
import Footer from "../../../components/footer/Footer";
import { Grid, Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import LoginForm from "./LoginForm";
import logo from "../../../assets/images/denck-dran-logo.svg";
import logoFR from "../../../assets/images/denck-dran-logo-fr.svg";
import logoIT from "../../../assets/images/denck-dran-logo-it.svg";

const LoginAdmin = () => {
  const { t, i18n } = useTranslation();
  const logoSrc =
    i18n.language === "de" ? logo : i18n.language === "fr" ? logoFR : logoIT;
  const navigate = useNavigate();

  const goToPraxisLogin = () => {
    navigate(`/${i18n.language}`);
  };

  return (
    <div className="login">
      <div className="admin-login-wrapper">
        <HelmetProvider>
          <Helmet>
            <title>{t("general.title")}</title>
          </Helmet>
        </HelmetProvider>

        <HeaderAdminLogin />

        <Grid container className="login-grid-container">
          <Grid className="login-patient-col" item xs={0} md={6}>
            <div className={adminlogin.patientbg}></div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={adminlogin.container}>
              <div className={adminlogin.box}>
                <div className="login-box__stack">
                  <div className="logo">
                    <img
                      src={logoSrc}
                      className="login-box__logo"
                      alt="Denck Dran"
                    />
                  </div>

                  <p className="text-center">{t("login_admin.not_a_member")}</p>

                  <Stack direction="column" spacing={2}>
                    <Button
                      onClick={goToPraxisLogin}
                      variant="contained"
                      fullWidth
                      aria-label="Zum Projekt-Beschrieb"
                    >
                      {t("login_admin.to_login_praxis")}
                    </Button>
                  </Stack>

                  <hr className="login-box__divider" />

                  <LoginForm />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Footer />
      </div>
    </div>
  );
};

export default LoginAdmin;
