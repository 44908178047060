import React, { useState, useEffect } from "react";
import "./NeuePraxis.scss";
import { useTranslation } from "react-i18next";
import NeuePraxisForm from "./NeuePraxisForm";
import { getTerritories } from "../../../services/admin/admin.service";

const NeuePraxis = () => {
  const { t, i18n } = useTranslation();
  const [territories, setTerritories] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await getTerritories();
      if (response?.status !== 200) return;
      setTerritories(response.data);
    }
    fetchData();
  }, []);

  return (
    <div className="neue-praxis box box--white">
      <div className="neue-praxis__header box__header">
        <div>
          <h1>{t("admin.neue_praxis.header")}</h1>
        </div>
      </div>
      <NeuePraxisForm t={t} i18n={i18n} territories={territories} />
    </div>
  );
};

export default NeuePraxis;
