import React from "react";
import "./Login.scss";
import stylescss from "./LoginInfoButtons.module.scss";
import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import flyer from "../../../assets/documents/DenCKDran_CH-6349_Info_Flyer_HCPs_DE.pdf";
import flyerFR from "../../../assets/documents/DenCKDran_CH-6349_Info_Flyer_HCPs_FR.pdf";
import flyerIT from "../../../assets/documents/DenCKDran_CH-6349_Info_Flyer_HCPs_IT.pdf";
import iconPDF from "../../../assets/images/icons/pdf-icon.svg";
import iconContact from "../../../assets/images/icons/contact-icon.svg";
import iconInterview from "../../../assets/images/icons/question-icon.svg";

const LoginInfoButtons = () => {
  const { t, i18n } = useTranslation();
  const flyerLink =
    i18n.language === "de" ? flyer : i18n.language === "fr" ? flyerFR : flyerIT;

  return (
    <Stack spacing={3}>
      {/* Hide for now, until new version is approved
      <div>
        <p className={stylescss.question}>{t("login.brauchen_sie")}</p>
        <Button
          className={stylescss.button}
          href={flyerLink}
          target="_blank"
          variant="outlined"
          color="secondary"
          aria-label="Zum Projekt-Beschrieb"
          fullWidth
        >
          <img src={iconPDF} alt="PDF icon" />
          <span>
            <strong>{t("login.project_description")}</strong>
          </span>
        </Button> 
      </div>

      <hr className={stylescss.divider} />
      */}

      <Stack spacing={2}>
        <Button
          className={stylescss.button}
          href={`mailto:ckd@astrazeneca.com?subject=${t("login.contact_us")}`}
          variant="outlined"
          aria-label="Kontaktieren Sie uns"
          fullWidth
        >
          <img src={iconContact} alt="Contact icon" />
          <span>
            <strong>{t("login.contact_us")}</strong>
          </span>
        </Button>
        <Button
          className={stylescss.button}
          href={`mailto:ckd@astrazeneca.com?subject=${t(
            "login.interview_participant"
          )}`}
          variant="outlined"
          aria-label="Einen Teilnehmer befragen"
          fullWidth
        >
          <img src={iconInterview} alt="Participant interview icon" />
          <span>
            <strong>{t("login.interview_participant")}</strong>
          </span>
        </Button>
      </Stack>
    </Stack>
  );
};

export default LoginInfoButtons;
