import React from "react";
import { useTranslation } from "react-i18next";
import { Stack, Grid } from "@mui/material";
import ProgressBar from "../../../components/progressbar/ProgressBar";
import StatisticsBox from "../../../components/StatisticsBox/StatisticsBox";

const legends = [
  {
    id: 1,
    label: "dashboard.nb_diagnosed_patients",
    color: "primary",
  },
  {
    id: 2,
    label: "dashboard.nb_tested_patients",
    color: "secondary",
  },
  {
    id: 3,
    label: "dashboard.nb_risk_patients",
    color: "lightblue",
  },
];

export default function DashboardGrid({ statistics }) {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <BaselineProgressBar t={t} statistics={statistics} />

      <ScreeningProgressBar t={t} statistics={statistics} />

      <TotalPatients t={t} statistics={statistics} />

      <NumberPatientsAtRisk t={t} statistics={statistics} />

      <NumberPatientsTested t={t} statistics={statistics} />

      <NumberCKDdiagnoses t={t} statistics={statistics} />
    </Grid>
  );
}

const BaselineProgressBar = ({ statistics, t }) => {
  return (
    <Grid item md={12} lg={6}>
      <div className="card card--white">
        <div className="card__header">
          <h2>{t("dashboard.baseline")}</h2>
        </div>
        <div className="card__content">
          <Stack
            direction="column"
            spacing={{ xs: 2, sm: 4, xl: 6 }}
            sx={{ width: 1 }}
          >
            <div className="progress">
              <ProgressBar
                percentage={statistics.Baseline.DiagnosedPatients.Percentage}
                number={statistics.Baseline.DiagnosedPatients.Nb}
                bgColor="var(--clr-orange)"
                zIndex="4"
              />
              <ProgressBar
                percentage={statistics.Baseline.TestedPatients.Percentage}
                number={statistics.Baseline.TestedPatients.Nb}
                bgColor="var(--clr-blue)"
                zIndex="3"
              />
              <ProgressBar
                isTotal={true}
                percentage={statistics.Baseline.RiskPatients.Percentage}
                number={statistics.Baseline.RiskPatients.Nb}
                bgColor="var(--clr-lightblue)"
                zIndex="2"
              />
            </div>
            <div className="legend-baseline-screening">
              <Legend />
            </div>
          </Stack>
        </div>
      </div>
    </Grid>
  );
};

const ScreeningProgressBar = ({ statistics, t }) => {
  return (
    <Grid item md={12} lg={6}>
      <div className="card card--white">
        <div className="card__header">
          <h2>{t("dashboard.screening")}</h2>
        </div>
        <div className="card__content">
          <Stack
            direction="column"
            spacing={{ xs: 2, sm: 4, xl: 6 }}
            sx={{ width: 1 }}
          >
            <div className="progress">
              <ProgressBar
                zIndex="4"
                percentage={statistics.Screening.DiagnosedPatients.Percentage}
                number={statistics.Screening.DiagnosedPatients.Nb}
                bgColor="var(--clr-orange)"
              />
              <ProgressBar
                zIndex="3"
                percentage={statistics.Screening.TestedPatients.Percentage}
                number={statistics.Screening.TestedPatients.Nb}
                bgColor="var(--clr-blue)"
              />
              <ProgressBar
                zIndex="2"
                isTotal={true}
                percentage={statistics.Screening.RiskPatients.Percentage}
                number={statistics.Screening.RiskPatients.Nb}
                bgColor="var(--clr-lightblue)"
              />
            </div>
            <div className="legend-baseline-screening">
              <Legend />
            </div>
          </Stack>
        </div>
      </div>
    </Grid>
  );
};

const Legend = () => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      {legends.map((legend) => (
        <Grid item xs={4} key={legend.id}>
          <Stack direction="row" spacing={1} alignItems="center">
            <span
              className={`legend__color legend__color--${legend.color}`}
            ></span>
            <span className="legend__label">{t(legend.label)}</span>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

const TotalPatients = ({ statistics, t }) => {
  return (
    <Grid item md={12} lg={6}>
      <div className="dashboard-stat-box dashboard-stat-box--multiline dashboard-stat-box--lightblue">
        <div className="dashboard-stat-box--line">
          <span className="dashboard-stat-box__label">
            {t("dashboard.nb_total_patients")}
          </span>
          <span className="dashboard-stat-box__result">
            {statistics.PatientsAtRisksStats.TotalPatients}
          </span>
        </div>
        <div className="dashboard-stat-box--line">
          <span className="dashboard-stat-box__label">
            {t("dashboard.nb_risk_patients")}
          </span>
          <span className="dashboard-stat-box__result">
            {statistics.PatientsAtRisksStats.TotalPatientsAtRisk}
          </span>
        </div>
        <div className="dashboard-stat-box--line">
          <span className="dashboard-stat-box__label">
            {t("dashboard.percentage_risk_patients")}
          </span>
          <span className="dashboard-stat-box__result">
            {statistics.PatientsAtRisksStats.PercentagePatientsAtRisk}%
          </span>
        </div>
      </div>
    </Grid>
  );
};

const NumberPatientsAtRisk = ({ statistics, t }) => {
  return (
    <Grid item md={12} lg={6}>
      <StatisticsBox
        label="statistics.nb_patients_atrisk"
        value={statistics.ChangeRiskPatients.ChangePP}
        color="lightblue"
        secondaryText={t("statistics.absolut_vs", {
          baseline: statistics.ChangeRiskPatients.BaselineNb,
          screening: statistics.ChangeRiskPatients.ScreeningNb,
        })}
      />
    </Grid>
  );
};

const NumberPatientsTested = ({ statistics, t }) => {
  return (
    <Grid item md={12} lg={6}>
      <StatisticsBox
        label="statistics.nb_patients_tested"
        value={statistics.ChangeTestedPatients.ChangePP}
        color="secondary"
        secondaryText={t("statistics.in_percentage_of_patients_atrisk")}
      />
    </Grid>
  );
};

const NumberCKDdiagnoses = ({ statistics, t }) => {
  return (
    <Grid item md={12} lg={6}>
      <StatisticsBox
        label="statistics.nb_ckd_diagnoses"
        value={statistics.ChangeDiagnosedPatients.ChangePP}
        color="primary"
        secondaryText={t("statistics.in_percentage_of_patients_atrisk")}
      />
    </Grid>
  );
};
