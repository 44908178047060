import React, { useEffect, useState } from "react";
import "./PraxisCKD.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  getPraxisRecords,
  updatePraxisRecords,
} from "../../../../services/admin/praxis.service";
import PraxisBaseline from "./PraxisBaseline";
import PraxisScreening from "./PraxisScreening";
import { Divider, Stack, Button } from "@mui/material";

const PraxisCkd = ({ praxis }) => {
  const [records, setRecords] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const response = await getPraxisRecords(praxis.PraxisId);
      if (response?.status !== 200) {
        handleErrors(response.status);
      }
      setRecords(response.data);
    }
    fetchData();
  }, [praxis]);

  const handleErrors = (status) => {
    if (status === 401) {
      redirectUserUnauthorized();
      return;
    }
    enqueueSnackbar(t("error_msg.error_contact_admin"), {
      variant: "error",
      autoHideDuration: 4000,
    });
    return;
  };

  const redirectUserUnauthorized = () => {
    sessionStorage.removeItem("currentAdmin");
    enqueueSnackbar(t("error_msg.please_log_in_again"), {
      variant: "error",
      autoHideDuration: 4000,
      onClose: () => {
        navigate(`/${i18n.language}/admin`);
      },
    });
  };

  const handleUpdateRecords = (newRecords) => {
    setRecords(newRecords);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const response = await updatePraxisRecords(records);
    if(response?.status !== 200) {
      setIsSubmitting(false);
      enqueueSnackbar(t("error_msg.error_contact_admin"), {
        variant: "error",
        autoHideDuration: 4000,
      });
      return;
    }
    setIsSubmitting(false);
    enqueueSnackbar(t("admin.praxis_ckd.success_msg"), {
      variant: "success",
      autoHideDuration: 4000,
    });
  };

  return records ? (
    <Stack
      direction="column"
      spacing={4}
      divider={<Divider orientation="horizontal" flexItem />}
    >
      <PraxisBaseline records={records} updateBaseline={handleUpdateRecords} />
      <PraxisScreening
        records={records}
        updateScreening={handleUpdateRecords}
      />
      <div className="praxis-ckd__actions">
        <Button onClick={handleSubmit} disabled={isSubmitting} variant="contained" color="secondary">
          {t("general.update")}
        </Button>
      </div>
    </Stack>
  ) : (
    <div>{t("general.loading")}</div>
  );
};

export default PraxisCkd;
