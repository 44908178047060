import React from "react";
import "./Menu.scss";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/denck-dran-logo.svg";
import logoFR from "../../assets/images/denck-dran-logo-fr.svg";
import logoIT from "../../assets/images/denck-dran-logo-it.svg";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import EventIcon from "@mui/icons-material/Event";
import QuizIcon from "@mui/icons-material/Quiz";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../store/auth";

const STATUS_ID_BASELINE = 2;
const STATUS_ID_SCREENING = 3;
const STATUS_ID_EXIT = 4;

const Menu = () => {
  const { t, i18n } = useTranslation();
  const { authState } = useAuth();
  const logoSrc =
    i18n.language === "de" ? logo : i18n.language === "fr" ? logoFR : logoIT;
  const status = authState.praxis.StatusId;
  let disableClassName = "disabled-link";

  const baseUrl = `/${i18n.language}`;
  let activeClassName = "is-active";

  return (
    <nav className="menu">
      <div className="menu-container">
        <div className="menu-top">
          <img src={logoSrc} alt="Denck Dran" className="header__logo" />
        </div>
        <ul className="menu-list">
          <li className="menu-list__item">
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
              to={baseUrl + "/dashboard"}
            >
              <DashboardIcon />
              <span>{t("navigation.dashboard")}</span>
            </NavLink>
          </li>
          <li className="menu-list__item">
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
              to={baseUrl + "/status-quo"}
            >
              <AssessmentIcon />
              <span>{t("navigation.status_quo")}</span>
            </NavLink>
          </li>
          <li className="menu-list__item">
            <NavLink
              className={({ isActive }) =>
                status < STATUS_ID_BASELINE
                  ? disableClassName
                  : isActive
                  ? activeClassName
                  : undefined
              }
              to={baseUrl + "/baseline"}
            >
              <ViewAgendaIcon />
              <span>{t("navigation.baseline")}</span>
            </NavLink>
          </li>
          <li className="menu-list__item">
            <NavLink
              className={({ isActive }) =>
                status < STATUS_ID_SCREENING
                  ? disableClassName
                  : isActive
                  ? activeClassName
                  : undefined
              }
              to={baseUrl + "/screening"}
            >
              <EventIcon />
              <span>{t("navigation.screening")}</span>
            </NavLink>
          </li>
          <li className="menu-list__item">
            <NavLink
              className={({ isActive }) =>
                status < STATUS_ID_EXIT
                  ? disableClassName
                  : isActive
                  ? activeClassName
                  : undefined
              }
              to={baseUrl + "/exit-fragebogen"}
            >
              <QuizIcon />
              <span>{t("navigation.exit")}</span>
            </NavLink>
          </li>
          <li className="menu-list__item">
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
              to={baseUrl + "/ressourcen"}
            >
              <LibraryBooksIcon />
              <span>{t("navigation.resources")}</span>
            </NavLink>
          </li>
          <li className="menu-list__item">
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
              to={baseUrl + "/praxis-details"}
            >
              <SettingsIcon />
              <span>{t("navigation.praxis_details")}</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Menu;
