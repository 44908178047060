import styles from "./LoginCarousel.module.scss";
import arrowLeft from "../../../../assets/images/carousel/arrow-left.svg";
import arrowRight from "../../../../assets/images/carousel/arrow-right.svg";

export const PrevButton = ({ enabled, onClick }) => (
  <button
    className={`${styles["button"]} ${styles["buttonPrev"]}`}
    onClick={onClick}
    disabled={!enabled}
  >
    <img src={arrowLeft} alt="arrow left" />
  </button>
);

export const NextButton = ({ enabled, onClick }) => (
  <button
    className={`${styles["button"]} ${styles["buttonNext"]}`}
    onClick={onClick}
    disabled={!enabled}
  >
    <img src={arrowRight} alt="arrow right" />
  </button>
);

export const DotButton = ({ selected, onClick }) => (
  <button
    className={`${styles.dot} ${selected ? styles.isSelected : ""}`}
    type="button"
    onClick={onClick}
  />
);
