import React from "react";
import "./Resources.scss";
import { useTranslation } from "react-i18next";
import Downloadables from "./Downloadables";
import ExternalLinks from "./ExternalLinks";
import Tools from "./Tools";
import Newsletters from "./Newsletters";
import Anchors from "./Anchors/Anchors";

const Resources = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="resources box box--white">
      <div className="resources-header box__header">
        <div className="resources-header__container">
          <h1>{t("resources.header")}</h1>
          <Anchors />
        </div>
      </div>
      <div className="resources-body box__body">
        <Downloadables t={t} i18n={i18n} />

        <Newsletters t={t} i18n={i18n} />

        <ExternalLinks t={t} i18n={i18n} />

        <Tools t={t} i18n={i18n} />
      </div>
    </div>
  );
};

export default Resources;
