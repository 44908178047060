import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./Praxis.scss";
import { TabPanel, a11yProps } from "../../../components/tabs/TabPanel";
import PraxisStats from "./PraxisStats";
import PraxisDetails from "./PraxisDetails";
import PraxisStatusQuo from "./PraxisStatusQuo";
import PraxisCkd from "./PraxisCKD";
import PraxisExit from "./PraxisExit";

const PraxisTabs = ({ praxis }) => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "var(--clr-blue-t200)" }}>
        <Tabs value={value} onChange={handleChange} aria-label="praxis tabs">
          <Tab label={t("admin.praxis_tabs.praxis_stats")} {...a11yProps(0)} />
          <Tab label={t("admin.praxis_tabs.praxis_date")} {...a11yProps(1)} />
          <Tab label={t("admin.praxis_tabs.status_quo_form")} {...a11yProps(2)} />
          <Tab label={t("admin.praxis_tabs.ckd_patients")} {...a11yProps(3)} />
          <Tab label={t("admin.praxis_tabs.exit_form")} {...a11yProps(4)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <PraxisStats praxis={praxis} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PraxisDetails praxis={praxis} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PraxisStatusQuo praxis={praxis} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PraxisCkd praxis={praxis} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <PraxisExit praxis={praxis} />
      </TabPanel>
    </>
  );
};

export default PraxisTabs;
