import React, { useEffect, useReducer } from "react";
import {
  Grid,
  Button,
  TextField,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import {
  PraxisReducer,
  initialState,
} from "../../../store/praxis/praxis.reducer";
import { updatePraxisDetails } from "./PraxisDetailsAPI";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const PraxisForm = ({ t, i18n }) => {
  const [formData, setFormData] = useReducer(PraxisReducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const praxis = sessionStorage.getItem("currentPraxis")
      ? JSON.parse(sessionStorage.getItem("currentPraxis"))
      : null;
    if (praxis) setFormData({ type: "UPDATE_PRAXIS_DATA", payload: praxis });
  }, []);

  function redirectUserUnauthorized() {
    sessionStorage.removeItem("currentPraxis");
    enqueueSnackbar(t("error_msg.please_log_in_again"), {
      variant: "error",
      autoHideDuration: 4000,
    });
    navigate("/" + i18n.language);
  }

  const handleChange = (event) => {
    setFormData({ type: "ON_CHANGE", payload: { event } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({ type: "REQUEST_UPDATE" });

    updatePraxisDetails(formData.praxis).then((response) => {
      if (response.status !== 200) {
        if (response.status === 401) {
          redirectUserUnauthorized();
          return;
        }
        const errorMsg = response.data.Message
          ? response.data.Message
          : response.data;
        setFormData({ type: "UPDATE_FAILED", error: errorMsg });
        enqueueSnackbar(t("praxis_details.praxis_error_msg"), {
          variant: "error",
          autoHideDuration: 4000,
        });
        return;
      }

      sessionStorage.setItem("currentPraxis", JSON.stringify(response.data));
      setFormData({ type: "UPDATE_SUCCESS", payload: response.data });
      enqueueSnackbar(t("praxis_details.praxis_success_msg"), {
        variant: "success",
        autoHideDuration: 4000,
        onClose: () => {
          if (
            i18n.language.toLowerCase() !==
            formData.praxis.Language.toLowerCase()
          ) {
            //change app language
            i18n.changeLanguage(formData.praxis.Language.toLowerCase());
            navigate(
              "/" + formData.praxis.Language.toLowerCase() + "/praxis-details"
            );
          }
        },
      });
    });
  };

  return (
    <>
      <div className="box__body">
        <form className="praxis-form">
          <div>
            <h3>{t("praxis_details.info")}</h3>
            <Grid container spacing={2} rowSpacing={2}>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  name="PraxisName"
                  label={t("praxis_details.praxis_name")}
                  variant="outlined"
                  fullWidth
                  required
                  onChange={handleChange}
                  value={formData.praxis.PraxisName || ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  name="PraxisAddress"
                  label={t("praxis_details.praxis_address")}
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={formData.praxis.PraxisAddress || ""}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  name="PraxisZipCode"
                  label={t("praxis_details.praxis_zip_code")}
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={formData.praxis.PraxisZipCode || ""}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  name="PraxisCity"
                  label={t("praxis_details.praxis_city")}
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={formData.praxis.PraxisCity || ""}
                />
              </Grid>
            </Grid>
          </div>
          <div>
            <h3>{t("praxis_details.contact")}</h3>
            <Grid container spacing={2} rowSpacing={2}>
              <Grid item md={6}>
                <FormControl>
                  <FormLabel component="legend">
                    {t("praxis_details.gender")}
                  </FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="ContactGender"
                    value={formData.praxis.ContactGender || ""}
                    onChange={handleChange}
                    row
                  >
                    <FormControlLabel
                      value="frau"
                      control={<Radio />}
                      label={t("praxis_details.gender_values.gender_frau")}
                    />
                    <FormControlLabel
                      value="mann"
                      control={<Radio />}
                      label={t("praxis_details.gender_values.gender_mann")}
                    />
                    <FormControlLabel
                      value="divers"
                      control={<Radio />}
                      label={t("praxis_details.gender_values.gender_divers")}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl>
                  <FormLabel component="legend">
                    {t("praxis_details.language")}:
                  </FormLabel>
                  <RadioGroup
                    aria-label="sprache"
                    name="Language"
                    value={formData.praxis.Language || "DE"}
                    onChange={handleChange}
                    row
                  >
                    <FormControlLabel
                      value="DE"
                      control={<Radio />}
                      label={t("praxis_details.language_DE")}
                    />
                    <FormControlLabel
                      value="FR"
                      control={<Radio />}
                      label={t("praxis_details.language_FR")}
                    />
                    <FormControlLabel
                      value="IT"
                      control={<Radio />}
                      label={t("praxis_details.language_IT")}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <TextField
                  type="text"
                  name="ContactFirstName"
                  label={t("praxis_details.first_name")}
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={formData.praxis.ContactFirstName || ""}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  type="text"
                  name="ContactLastName"
                  label={t("praxis_details.last_name")}
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={formData.praxis.ContactLastName || ""}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  name="ContactPhone"
                  label={t("praxis_details.phone_number")}
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={formData.praxis.ContactPhone}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="email"
                  name="Email"
                  label={t("praxis_details.email")}
                  variant="outlined"
                  required
                  fullWidth
                  onChange={handleChange}
                  value={formData.praxis.Email || ""}
                  disabled
                />
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
      <div className="box__footer">
        <Button
          onClick={handleSubmit}
          disabled={formData.loading}
          variant="contained"
          color="secondary"
        >
          {t("general.update")}
        </Button>
      </div>
    </>
  );
};

export default PraxisForm;
