import React from "react";
import "./Anchors.scss";
import AnchorItem from "./AnchorItem";

const anchorList = [
  {
    id: 1,
    title: "resources.downloadables.title",
    link: "materialien",
  },
  {
    id: 2,
    title: "resources.newsletters.title",
    link: "newsletters",
  },
  {
    id: 3,
    title: "resources.external_links.title",
    link: "nutzlicheLinks",
  },
  {
    id: 4,
    title: "resources.tools.title",
    link: "rechnerTools",
  },
];

const Anchors = () => {
  return (
    <ul className="anchors">
      {anchorList.map(({ id, title, link }) => (
        <AnchorItem key={id} title={title} link={link} />
      ))}
    </ul>
  );
};

export default Anchors;
