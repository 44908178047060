import React from "react";
import { Stack, TextField, Grid } from "@mui/material";
import Moment from "react-moment";
import { Trans, useTranslation } from "react-i18next";

const PraxisBaseline = ({ records, updateBaseline }) => {
  const { t } = useTranslation();

  const handleChange = (event, data) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    const newRecords = {
      ...records,
      Baseline: records.Baseline.map((baseline) => {
        if (baseline.RecordDate === data.RecordDate) {
          return { ...baseline, [inputName]: inputValue };
        } else {
          return baseline;
        }
      }),
    };
    updateBaseline(newRecords);
  };

  return (
    <div className="baseline-form">
      <h2>{t("admin.dashboard.baseline")}</h2>
      <Grid container columns={14} spacing={2}>
        <Grid item md={4}>
          <div className="baseline-form__parameters">
            <div>
              <span>{t("definition.patients_total")}</span>
            </div>
            <div>
              <span>{t("definition.patients_atrisk")}</span>
            </div>
            <div>
              <span>
                <Trans i18nKey="definition.patients_tested" />
              </span>
            </div>
            <div>
              <span>
                <Trans i18nKey="definition.patients_diagnosed" />
              </span>
            </div>
          </div>
        </Grid>

        {records.Baseline.map((data, index) => (
          <Grid item md={2} key={data.RecordDate}>
            <Stack spacing={2}>
              <div className="days">
                <span>{data.DayOfTheWeek}</span>
                <br />
                <Moment format="DD.MM.YYYY">{data.RecordDate}</Moment>
              </div>
              <div>
                <TextField
                  type="number"
                  name="Total"
                  variant="outlined"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => handleChange(e, data)}
                  value={data.Total ?? ""}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </div>
              <div>
                <TextField
                  type="number"
                  name="AtRisk"
                  variant="outlined"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => handleChange(e, data)}
                  value={data.AtRisk ?? ""}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </div>
              <div>
                <TextField
                  type="number"
                  name="Tested"
                  variant="outlined"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => handleChange(e, data)}
                  value={data.Tested ?? ""}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </div>
              <div>
                <TextField
                  type="number"
                  name="Diagnosed"
                  variant="outlined"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => handleChange(e, data)}
                  value={data.Diagnosed ?? ""}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </div>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default PraxisBaseline;
