import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getPraxisStatistics } from "../../../services/praxis/praxis.service";
import DashboardGrid from "./DashboardGrid";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const response = await getPraxisStatistics();
      setIsLoading(false);
      if (response?.status !== 200) {
        handleErrors(response.status);
        return;
      }
      setStats(response.data);
    }
    fetchData();
  }, []);

  const handleErrors = (status) => {
    if (status === 401) {
      redirectUserUnauthorized();
      return;
    }
    if (status === 403) {
      return;
    }
    enqueueSnackbar(t("error_msg.error_contact_admin"), {
      variant: "error",
      autoHideDuration: 4000,
    });
    return;
  };

  const redirectUserUnauthorized = () => {
    sessionStorage.removeItem("currentPraxis");
    enqueueSnackbar(t("error_msg.please_log_in_again"), {
      variant: "error",
      autoHideDuration: 4000,
    });
    navigate("/" + i18n.language);
  };

  const NoStatistics = ({ t }) => {
    return (
      <div className="praxis-stats__no-data">
        {t("general.no_data_available")}
      </div>
    );
  };

  return (
    <div className="dashboard-view">
      {isLoading ? (
        <div>{t("general.loading")}</div>
      ) : (
        stats ? (
          <DashboardGrid statistics={stats} />
        ) : (
          <NoStatistics t={t} />
        ) 
      )}
    </div>
  );
}
