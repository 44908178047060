import React from "react";
import "./NotFound.scss";
import Footer from "../components/footer/Footer";
import logo from "../assets/images/denck-dran-logo.svg";
import logoFR from "../assets/images/denck-dran-logo-fr.svg";
import logoIT from "../assets/images/denck-dran-logo-it.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NotFound = () => {
  const { t, i18n } = useTranslation();
  const logoSrc = i18n.language === "de" ? logo : i18n.language === "fr" ? logoFR : logoIT;

  return (
    <div className="not-found">
      <div className="not-found-wrapper">
        <div className="not-found-header"></div>

        <div className="not-found-content">
          <div className="not-found__logo">
            <img
              src={logoSrc}
              className="not-found__logo-image"
              alt="Denck Dran"
            />
          </div>
          <h1 className="text-center">{t("not_found.title")}</h1>
          <Link className="not-found__back-link" to={"/" + i18n.language}>
            {t("forgot_password.back_login")}
          </Link>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default NotFound;
