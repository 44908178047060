import PropTypes from "prop-types";
import "./ProgressBar.scss";
import Tooltip from "@mui/material/Tooltip";

const ProgressBar = ({ isTotal, percentage, number, bgColor, zIndex }) => {
  const progressPercentage = !isTotal ? `${percentage}%` : null;
  const percentageWidth = percentage > 100 ? 100 : percentage;
  return (
    percentage !== 0 && (
      <>
        <Tooltip title={`${percentage}%`} arrow placement="top-end">
          <div
            role="progressbar"
            style={{
              width: percentageWidth + "%",
              backgroundColor: bgColor,
              zIndex: zIndex,
              borderRadius: isTotal && "0.5rem",
            }}
            className="progress-bar"
          >
            {progressPercentage}
          </div>
        </Tooltip>
        <div
          className="progress-bar__number"
          style={{ left: percentageWidth + "%", color: bgColor }}
        >
          {number}
        </div>
      </>
    )
  );
};

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
  bgColor: PropTypes.string,
};

export default ProgressBar;
