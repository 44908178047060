import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, TextField, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { forgotPassword } from "../../../services/login.service";
import { useNavigate, Link } from "react-router-dom";
import { useSnackbar } from "notistack";

const ForgotPasswordForm = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async ({ email }) => {
    if (email.length === 0) return;

    const response = await forgotPassword({
      email,
    });

    handleWSResponse(response);
  };

  const handleWSResponse = (response) => {
    if (response.status !== 200) {
      enqueueSnackbar(t("forgot_password.error_msg"), {
        variant: "error",
        autoHideDuration: 4000,
      });
      return;
    }

    if (response.data) {
      enqueueSnackbar(t("forgot_password.success_msg"), {
        variant: "success",
        autoHideDuration: 4000,
      });
      navigate("/" + i18n.language);
    } else {
      enqueueSnackbar(t("forgot_password.error_msg"), {
        variant: "error",
        autoHideDuration: 4000,
      });
      return;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" spacing={2}>
          <div>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  error={Boolean(errors.email)}
                  type="email"
                  label={t("forgot_password.email")}
                  {...field}
                />
              )}
              rules={{
                required: t("forgot_password.email_required"),
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t("forgot_password.email_not_valid"),
                },
              }}
            />
            {errors.email && (
              <p className="helper-text helper-text--error">
                {errors.email.message}
              </p>
            )}
          </div>

          <Button
            type="submit"
            variant="contained"
            aria-label="confirm"
            disabled={isSubmitting}
          >
            {t("forgot_password.confirm")}
          </Button>

          <Link className="forgotpassword__back-link" to={"/" + i18n.language}>
            {t("forgot_password.back_login")}
          </Link>
        </Stack>
      </form>
    </>
  );
};

export default ForgotPasswordForm;
