import React, { useEffect } from "react";
import {
  Button,
  TextField,
  Rating,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FormInputMultiCheckbox } from "../../../components/form/FormInputMultiCheckbox";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getStatusQuo, saveStatusQuo } from "./StatusQuoAPI";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../store/auth/auth.context";
import { ErrorMessage } from "../../../components/form";

const STATUS_ID = 1;

const defaultValues = {
  Q1: 0,
  Q2: "",
  Q3: "",
  Q4: "",
  Q5: "",
  Q6: "",
  Q7: 0,
  Q8: "",
  Q9: "",
  Q10: "",
  Q11: [],
};

const StatusQuoForm = () => {
  const { t, i18n } = useTranslation();
  const schema = yup.object({
    Q1: yup
      .number()
      .min(1, t("error_msg.mandatory_field"))
      .required(t("error_msg.mandatory_field")),
    Q2: yup.string().required(t("error_msg.mandatory_field")),
    Q3: yup.string().required(t("error_msg.mandatory_field")),
    Q4: yup.string().required(t("error_msg.mandatory_field")),
    Q5: yup.string().required(t("error_msg.mandatory_field")),
    Q6: yup.string().required(t("error_msg.mandatory_field")),
    Q7: yup
      .number()
      .min(1, t("error_msg.mandatory_field"))
      .required(t("error_msg.mandatory_field")),
    Q8: yup.string().required(t("error_msg.mandatory_field")),
    Q9: yup.string().required(t("error_msg.mandatory_field")),
    Q10: yup.string().required(t("error_msg.mandatory_field")),
    Q11: yup.array().min(1).required(t("error_msg.mandatory_field")),
  });
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(schema) });
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { authState, dispatch } = useAuth();

  const options = [
    {
      label: t("status_quo.answers.question11.answer1"),
      value: "1",
    },
    {
      label: t("status_quo.answers.question11.answer2"),
      value: "2",
    },
    {
      label: t("status_quo.answers.question11.answer3"),
      value: "3",
    },
    {
      label: t("status_quo.answers.question11.answer4"),
      value: "4",
    },
    {
      label: t("status_quo.answers.question11.answer5"),
      value: "5",
    },
    {
      label: t("status_quo.answers.question11.answer6"),
      value: "6",
    },
    {
      label: t("status_quo.answers.question11.answer7"),
      value: "7",
    },
    {
      label: t("status_quo.answers.question11.answer8"),
      value: "8",
    },
    {
      label: t("status_quo.answers.question11.answer9"),
      value: "9",
    },
    {
      label: t("status_quo.answers.question11.answer10"),
      value: "10",
    },
  ];

  useEffect(() => {
    async function fetchData() {
      const response = await getStatusQuo();
      if (response.status !== 200) {
        redirectUserUnauthorized();
        return;
      }
      reset(response.data);
    }
    fetchData();
  }, [reset]);

  const redirectUserUnauthorized = () => {
    sessionStorage.removeItem("currentPraxis");
    enqueueSnackbar(t("error_msg.please_log_in_again"), {
      variant: "error",
      autoHideDuration: 4000,
    });
    navigate("/" + i18n.language);
  };

  const onSubmit = async (data) => {
    const response = await saveStatusQuo(data);
    if (response.status !== 200) {
      if (response.status === 401) {
        redirectUserUnauthorized();
        return;
      }
      enqueueSnackbar(t("status_quo.error_msg"), {
        variant: "error",
        autoHideDuration: 4000,
      });
      return;
    } else {
      enqueueSnackbar(t("status_quo.success_msg"), {
        variant: "success",
        autoHideDuration: 4000,
        onClose: () => {
          navigate("/" + i18n.language + "/baseline");
        },
      });
      dispatch({ type: "LOGIN_SUCCESS", payload: response.data });
      sessionStorage.setItem("currentPraxis", JSON.stringify(response.data));
    }
  };

  const isConfirmDisable =
    isSubmitting || authState.praxis.StatusId !== STATUS_ID;

  return (
    <>
      <div className="status-quo-body box__body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={isConfirmDisable}>
            <div className="status-quo-form">
              <div className="status-quo__question">
                <p className="status-quo__question-text">
                  {t("status_quo.questions.question1")}
                </p>
                <Controller
                  name="Q1"
                  defaultValue={defaultValues.Q1}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Rating
                        {...field}
                        onChange={(e) =>
                          field.onChange(parseInt(e.target.value))
                        }
                      />
                    );
                  }}
                />
                {errors.Q1 && <ErrorMessage error={errors.Q1?.message} />}
              </div>
              <div className="status-quo__question">
                <p className="status-quo__question-text">
                  {t("status_quo.questions.question2")}
                </p>
                <Controller
                  name="Q2"
                  defaultValue={defaultValues.Q2}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("general.answer")}
                      multiline
                      rows={3}
                      fullWidth
                      {...field}
                    />
                  )}
                />
                {errors.Q2 && <ErrorMessage error={errors.Q2?.message} />}
              </div>
              <div className="status-quo__question">
                <p className="status-quo__question-text">
                  {t("status_quo.questions.question3")}
                </p>
                <Controller
                  name="Q3"
                  defaultValue={defaultValues.Q3}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("general.answer")}
                      multiline
                      rows={3}
                      fullWidth
                      {...field}
                    />
                  )}
                />
                {errors.Q3 && <ErrorMessage error={errors.Q3?.message} />}
              </div>
              <div className="status-quo__question">
                <p className="status-quo__question-text">
                  {t("status_quo.questions.question4")}
                </p>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <Controller
                      name="Q4"
                      defaultValue={defaultValues.Q4}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          label={t("status_quo.questions.question4_a")}
                          multiline
                          rows={3}
                          fullWidth
                          {...field}
                        />
                      )}
                    />
                    {errors.Q4 && <ErrorMessage error={errors.Q4?.message} />}
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      name="Q5"
                      defaultValue={defaultValues.Q5}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          label={t("status_quo.questions.question4_b")}
                          multiline
                          rows={3}
                          fullWidth
                          {...field}
                        />
                      )}
                    />
                    {errors.Q5 && <ErrorMessage error={errors.Q5?.message} />}
                  </Grid>
                </Grid>
              </div>
              <div className="status-quo__question">
                <p className="status-quo__question-text">
                  {t("status_quo.questions.question6")}
                </p>
                <Controller
                  name="Q6"
                  defaultValue={defaultValues.Q6}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("general.answer")}
                      multiline
                      rows={3}
                      fullWidth
                      {...field}
                    />
                  )}
                />
                {errors.Q6 && <ErrorMessage error={errors.Q6?.message} />}
              </div>
              <div className="status-quo__question">
                <p className="status-quo__question-text">
                  {t("status_quo.questions.question7")}
                </p>
                <Controller
                  name="Q7"
                  defaultValue={defaultValues.Q7}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Rating
                        {...field}
                        onChange={(e) =>
                          field.onChange(parseInt(e.target.value))
                        }
                      />
                    );
                  }}
                />
                {errors.Q7 && <ErrorMessage error={errors.Q7?.message} />}
              </div>
              <div className="status-quo__question">
                <p className="status-quo__question-text">
                  {t("status_quo.questions.question8")}
                </p>
                <Controller
                  name="Q8"
                  defaultValue={defaultValues.Q8}
                  control={control}
                  render={({ field }) => (
                    <RadioGroup aria-label="Q8" row {...field}>
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label={t("status_quo.answers.question8.answer1")}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label={t("status_quo.answers.question8.answer2")}
                      />
                    </RadioGroup>
                  )}
                />
                {errors.Q8 && <ErrorMessage error={errors.Q8?.message} />}
              </div>
              <div className="status-quo__question">
                <p className="status-quo__question-text">
                  {t("status_quo.questions.question9")}
                </p>
                <Controller
                  name="Q9"
                  defaultValue={defaultValues.Q9}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("general.answer")}
                      multiline
                      rows={3}
                      fullWidth
                      {...field}
                    />
                  )}
                />
                {errors.Q9 && <ErrorMessage error={errors.Q9?.message} />}
              </div>
              <div className="status-quo__question">
                <p className="status-quo__question-text">
                  {t("status_quo.questions.question10")}
                </p>
                <Controller
                  name="Q10"
                  defaultValue={defaultValues.Q10}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("general.answer")}
                      multiline
                      rows={3}
                      fullWidth
                      {...field}
                    />
                  )}
                />
                {errors.Q10 && <ErrorMessage error={errors.Q10?.message} />}
              </div>
              <div className="status-quo__question">
                <p className="status-quo__question-text">
                  {t("status_quo.questions.question11")}
                </p>

                <FormInputMultiCheckbox
                  control={control}
                  setValue={setValue}
                  name={"Q11"}
                  label={"Q11"}
                  options={options}
                  watch={watch}
                />
                {errors.Q11 && <ErrorMessage error={errors.Q11?.message} />}
              </div>
            </div>
          </fieldset>
        </form>
      </div>

      <div className="status-quo-footnote">
        <p>{t("status_quo.footnotes")}</p>
      </div>

      <div className="status-quo-footer box__footer">
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="secondary"
          disabled={isConfirmDisable}
        >
          {t("general.confirm")}
        </Button>
      </div>
    </>
  );
};

export default StatusQuoForm;
