import React, { useState } from "react";
import {
  Button,
  TextField,
  Radio,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { createPraxis } from "../../../services/admin/admin.service";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import NeuePraxisFooter from "./NeuePraxisFooter";
import { ErrorMessage } from "../../../components/form";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const NeuePraxisForm = ({ t, i18n, territories }) => {
  const [open, setOpen] = useState(false);
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const schema = yup.object({
    PraxisName: yup.string().required(),
    Email: yup.string().email().required(),
    Language: yup.string().required(),
    StartDate: yup.date().required(),
    TerritoryId: yup.number().required(),
  });

  const isDECurrentLang = i18n.language === "de";
  const isFRCurrentLang = i18n.language === "fr";

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    const startDateWithoutUTC = moment(data.StartDate)
      .utcOffset(0, true)
      .format();
    data.StartDate = startDateWithoutUTC;
    const response = await createPraxis(data);
    if (response?.status !== 200) {
      handleErrors(response.status);
      return;
    }

    handleOpenPopup();
  };

  const handleErrors = (status) => {
    switch (status) {
      case 401:
        redirectUserUnauthorized();
        break;
      case 409:
        enqueueSnackbar(t("error_msg.email_already_used"), {
          variant: "error",
          autoHideDuration: 4000,
        });
        break;
      case 500:
        enqueueSnackbar(t("error_msg.error_contact_admin"), {
          variant: "error",
          autoHideDuration: 4000,
        });
        break;
      default:
        enqueueSnackbar(t("error_msg.error_contact_admin"), {
          variant: "error",
          autoHideDuration: 4000,
        });
    }
  };

  const redirectUserUnauthorized = () => {
    sessionStorage.removeItem("currentAdmin");
    enqueueSnackbar(t("error_msg.please_log_in_again"), {
      variant: "error",
      autoHideDuration: 4000,
      onClose: () => {
        navigate(`/${i18n.language}/admin`);
      },
    });
  };

  const handleOpenPopup = () => {
    setOpen(true);
  };

  const handleClosePopup = () => {
    setOpen(false);
    navigate(`/${i18n.language}/admin/praxen`);
  };

  return (
    <>
      <div className="neue-praxis__content">
        <form>
          <fieldset className="neue-praxis__fieldset" disabled={isSubmitting}>
            <div>
              <h2>{t("admin.neue_praxis.praxis")}</h2>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Controller
                    name="PraxisName"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label={t("praxis_details.praxis_name")}
                        required
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                  {errors.PraxisName && (
                    <ErrorMessage error={errors.PraxisName?.message} />
                  )}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Controller
                    name="PraxisAddress"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label={t("praxis_details.praxis_address")}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controller
                    name="PraxisZipCode"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label={t("praxis_details.praxis_zip_code")}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controller
                    name="PraxisCity"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label={t("praxis_details.praxis_city")}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl required sx={{ width: 1 }}>
                    <InputLabel id="territory-label">
                      {t("praxis_details.territory")}
                    </InputLabel>
                    <Controller
                      name="TerritoryId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          label="Territory *"
                          labelId="territory-label"
                          {...field}
                        >
                          {territories &&
                            territories.map((territory) => {
                              return (
                                <MenuItem
                                  key={territory.TerritoryId}
                                  value={territory.TerritoryId}
                                >
                                  {isDECurrentLang
                                    ? territory.TerritoryNameDE
                                    : isFRCurrentLang
                                    ? territory.TerritoryNameFR
                                    : territory.TerritoryNameIT}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      )}
                    />
                  </FormControl>
                  {errors.TerritoryId && (
                    <ErrorMessage error={errors.TerritoryId?.message} />
                  )}
                </Grid>
              </Grid>
            </div>
            <div>
              <h2>{t("admin.neue_praxis.contact")}</h2>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormLabel component="legend">
                    {t("praxis_details.gender")}:
                  </FormLabel>
                  <Controller
                    name="ContactGender"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <RadioGroup aria-label="gender" row {...field}>
                        <FormControlLabel
                          value="frau"
                          control={<Radio />}
                          label={t("praxis_details.gender_values.gender_frau")}
                        />
                        <FormControlLabel
                          value="mann"
                          control={<Radio />}
                          label={t("praxis_details.gender_values.gender_mann")}
                        />
                        <FormControlLabel
                          value="divers"
                          control={<Radio />}
                          label={t(
                            "praxis_details.gender_values.gender_divers"
                          )}
                        />
                      </RadioGroup>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormLabel component="legend">
                    {t("praxis_details.language")}: *
                  </FormLabel>
                  <Controller
                    name="Language"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <RadioGroup aria-label="sprache" row {...field}>
                        <FormControlLabel
                          value="DE"
                          control={<Radio />}
                          label={t("praxis_details.language_DE")}
                        />
                        <FormControlLabel
                          value="FR"
                          control={<Radio />}
                          label={t("praxis_details.language_FR")}
                        />
                        <FormControlLabel
                          value="IT"
                          control={<Radio />}
                          label={t("praxis_details.language_IT")}
                        />
                      </RadioGroup>
                    )}
                  />
                  {errors.Language && (
                    <ErrorMessage error={errors.Language?.message} />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="ContactFirstName"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label={t("praxis_details.first_name")}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="ContactLastName"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label={t("praxis_details.last_name")}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="ContactPhone"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type="tel"
                        label={t("praxis_details.phone_number")}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="Email"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type="email"
                        label={t("praxis_details.email")}
                        fullWidth
                        required
                        {...field}
                      />
                    )}
                  />
                  {errors.Email && (
                    <ErrorMessage error={errors.Email?.message} />
                  )}
                </Grid>
              </Grid>
            </div>
            <div>
              <h2>{t("admin.neue_praxis.screening_startdate")} *</h2>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="StartDate"
                    control={control}
                    render={({ field }) => (
                      <TextField type="date" required fullWidth {...field} />
                    )}
                  />
                  {errors.StartDate && (
                    <ErrorMessage error={errors.StartDate?.message} />
                  )}
                </Grid>
              </Grid>
            </div>
          </fieldset>
        </form>
      </div>

      <NeuePraxisFooter t={t} onSubmitClicked={handleSubmit(onSubmit)} />

      <Dialog open={open} onClose={handleClosePopup}>
        <DialogTitle id="alert-dialog-title">
          {t("admin.neue_praxis.success_popup.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("admin.neue_praxis.success_popup.text")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClosePopup}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NeuePraxisForm;
