import React from "react";
import "./Register.scss";
import HeaderLogin from "../../../components/header/HeaderLogin";
import Footer from "../../../components/footer/Footer";
import logo from "../../../assets/images/denck-dran-logo.svg";
import logoFR from "../../../assets/images/denck-dran-logo-fr.svg";
import logoIT from "../../../assets/images/denck-dran-logo-it.svg";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import RegisterForm from "./RegisterForm";
import { HelmetProvider, Helmet } from 'react-helmet-async';

const Register = () => {
  const { t, i18n } = useTranslation();
  const logoSrc = i18n.language === "de" ? logo : i18n.language === "fr" ? logoFR : logoIT;

  return (
    <div className="register">
      <div className="register-wrapper">
        <HelmetProvider><Helmet>
          <title>{t("general.title")}</title>
        </Helmet></HelmetProvider>
      
        <HeaderLogin showAZAccess={false} />

        <Grid container className="form-grid-container">
          <Grid item xs={12} md={6}>
            <div className="form-box-container">
              <div className="form-box">
                <div className="form-box__stack">
                  <div className="logo">
                    <img
                      src={logoSrc}
                      className="login-box__logo"
                      alt="Denck Dran"
                    />
                  </div>

                  <p className="text-center">
                    {t("register.welcome_msg")}
                    <br />
                    {t("register.login_with_details")}
                  </p>

                  <hr className="form-box__divider" />

                  <RegisterForm />
                </div>
              </div>
            </div>
          </Grid>
          <Grid className="login-patient-col" item xs={0} md={6}>
            <div className="login-patient-bg"></div>
          </Grid>
        </Grid>

        <Footer />
      </div>
    </div>
  );
};

export default Register;
