export const initialState = {
  praxis: {},
  loading: false,
  errorMessage: null,
};

export const PraxisReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_PRAXIS_DATA":
      return {
        ...state,
        praxis: action.payload
      };
    case "ON_CHANGE":
      const inputName = action.payload.event.target.name;
      const inputValue = action.payload.event.target.value;
      return {
        ...state,
        praxis: {
          ...state.praxis,
          [inputName]: inputValue,
        },
      };
    case "REQUEST_UPDATE":
      return {
        ...state,
        loading: true,
      };
    case "UPDATE_SUCCESS":
      return {
        ...state,
        praxis: action.payload,
        loading: false,
      };
    case "UPDATE_FAILED":
      return {
        ...state,
        loading: false,
        errorMessage: action.error,
      };
    default:
      return state;
  }
};
