import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

const HeaderContact = ({ t, territory }) => {
  return (
    <>
      {territory && (
        <div className="header__contact">
          <p className="header__contact-name">
            <strong>
              {t("header.your_contact")}: {territory.ContactName}
            </strong>
            <span>, {territory.ContactJob}</span>
          </p>
          <p className="header__contact-email">
            <EmailIcon />
            <a href={"mailto:" + territory.ContactEmail}>
              {territory.ContactEmail}
            </a>
            <PhoneIcon />
            <a href={"tel:" + territory.ContactPhone}>
              {territory.ContactPhone}
            </a>
          </p>
        </div>
      )}
    </>
  );
};

export default HeaderContact;
