import React, { useReducer, useContext, createContext } from "react";
import { Navigate, useLocation } from "react-router-dom"
import { initialState, AuthReducer } from "./auth.reducer";
import { useTranslation } from "react-i18next";

const AuthContext = createContext(null);

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
}

const AuthProvider = ({ children }) => {
  const [authState, dispatch] = useReducer(AuthReducer, initialState);

  let value = { authState, dispatch };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

function RequireAuth({ children }) {
  const { i18n } = useTranslation();
  const baseRouteUrl = "/" + i18n.language;
  let {authState} = useAuth();
  let location = useLocation();

  if (!authState.praxis) {
    return <Navigate to={baseRouteUrl + "/"} state={{ from: location }} replace />;
  }

  return children;
}

export { AuthProvider, useAuth, RequireAuth }