import { Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NeuePraxisFooter = ({ t, onSubmitClicked }) => {
  const navigate = useNavigate();
  const handleClickOnSave = () => {
    onSubmitClicked();
  };

  return (
    <div className="neue-praxis__footer">
      <Stack direction="row" spacing={2}>
        <Button
          onClick={() => navigate(-1)}
          variant="outlined"
          color="secondary"
        >
          {t("general.cancel")}
        </Button>
        <Button
          onClick={handleClickOnSave}
          variant="contained"
          color="secondary"
        >
          {t("general.save")}
        </Button>
      </Stack>
    </div>
  );
};

export default NeuePraxisFooter;
