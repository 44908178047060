import React, { useState } from "react";
import "./Header.scss";
import { Menu, MenuItem, Avatar } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonIcon from "@mui/icons-material/Person";
import { logoutAdmin } from "../../services/admin/azlogin.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAdmin } from "../../store/admin";

const HeaderAdmin = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { adminState, dispatch } = useAdmin();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logoutAdmin().then((response) => {
      dispatch({ type: "LOGOUT" });
      sessionStorage.removeItem("currentAdmin");
      navigate(`/${i18n.language}/admin`);
    });
    handleClose();
  };

  const goToMyAccount = () => {
    navigate(`/${i18n.language}/admin/myaccount`);
    handleClose();
  };

  return (
    <header className="header">
      <div className="header-wrapper">
        <div></div>

        <div>
          <div className="header__user" onClick={handleOpenMenu}>
            <Avatar>
              <PersonIcon />
            </Avatar>
            <span>{adminState.admin.Email || ""}</span>
            <KeyboardArrowDownIcon color="primary" />
          </div>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 1,
              sx: {
                width: 160,
              },
            }}
          >
            <MenuItem onClick={goToMyAccount}>
              {t("admin.general.my_account")}
            </MenuItem>
            <MenuItem onClick={handleLogout}>{t("general.logout")}</MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default HeaderAdmin;
