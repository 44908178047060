import React, { useReducer, useContext, createContext } from "react";
import { Navigate, useLocation } from "react-router-dom"
import { initialState, AdminReducer } from "./admin.reducer";
import { useTranslation } from "react-i18next";

const AdminContext = createContext(null);

function useAdmin() {
  const context = useContext(AdminContext);
  if (context === undefined) {
    throw new Error("useAdmin must be used within a AdminProvider");
  }

  return context;
}

const AdminProvider = ({ children }) => {
  const [adminState, dispatch] = useReducer(AdminReducer, initialState);

  let value = { adminState, dispatch };

  return (
    <AdminContext.Provider value={value}>
      {children}
    </AdminContext.Provider>
  );
};

function RequireAuthAdmin({ children }) {
  const { i18n } = useTranslation();
  const baseRouteUrl = `/${i18n.language}/admin`;
  let {adminState} = useAdmin();
  let location = useLocation();

  if (!adminState.admin) {
    return <Navigate to={baseRouteUrl} state={{ from: location }} replace />;
  }

  return children;
}

export { AdminProvider, useAdmin, RequireAuthAdmin }