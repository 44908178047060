import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import stylescss from "./LoginCarousel.module.scss";
import { PrevButton, NextButton } from "./LoginCarouselButtons";

const quotes = [
  {
    id: 1,
    text: "login.quotes.quote1.text",
    author: "login.quotes.quote1.author",
  },
  {
    id: 2,
    text: "login.quotes.quote2.text",
    author: "login.quotes.quote2.author",
  },
  {
    id: 3,
    text: "login.quotes.quote3.text",
    author: "login.quotes.quote3.author",
  },
  {
    id: 4,
    text: "login.quotes.quote4.text",
    author: "login.quotes.quote4.author",
  },
];

export const LoginCarousel = () => {
  const { t } = useTranslation();
  const [viewportRef, embla] = useEmblaCarousel(
    {
      skipSnaps: false,
      loop: true,
    },
    [Autoplay({ delay: 10000 })]
  );
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
  }, [embla, onSelect]);

  return (
    <>
      <div className={stylescss.carousel}>
        <div className={stylescss.box}>
          <div className={stylescss.viewport} ref={viewportRef}>
            <div className={stylescss.container}>
              {quotes.map((quote) => (
                <div className={stylescss.slide} key={quote.id}>
                  <p className={stylescss.quote}>{t(quote.text)}</p>
                  <p className={stylescss.author}>{t(quote.author)}</p>
                </div>
              ))}
              ;
            </div>
          </div>
        </div>
        <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
      </div>
    </>
  );
};
