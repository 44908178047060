import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import { Controller } from "react-hook-form";

export const FormInputMultiCheckbox = ({
  name,
  control,
  setValue,
  label,
  options,
  watch
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [initDone, setInitDone] = useState(false);

  const watchValues = watch(name);

  const handleSelect = (value) => {
    const isPresent = selectedItems.indexOf(value);
    if (isPresent !== -1) {
      const remaining = selectedItems.filter((item) => item !== value);
      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems) => [...prevItems, value]);
    }
  };

  useEffect(() => {
    // console.log('useEffect/selectedItems: ', selectedItems);
    setValue(name, selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    //Bad way to set initialValues coming from the WS... but seems to work
    if (watchValues)
    {
      // console.log('useEffect/watchValues: ',watchValues);
      if (!initDone) {
        setInitDone(true);
        setSelectedItems(watchValues);
        // console.log('useEffect/watchValues: setInitDone');
      }
    }
  }, [watchValues]);


  return (
    <FormControl variant={"outlined"}>
      {options.map((option) => {
        return (
          <FormControlLabel
            control={
              <Controller
                name={name}
                render={({}) => {
                  return (
                    <Checkbox
                      checked={selectedItems.includes(option.value)}
                      onChange={() => handleSelect(option.value)}
                    />
                  );
                }}
                control={control}
              />
            }
            label={option.label}
            key={option.value}
          />
        );
      })}
    </FormControl>
  );
};
