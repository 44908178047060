import React, { useState } from "react";
import uuid from "react-uuid";
import "./Resources.scss";
import ThumbDiagnoseCkdDE from "../../../assets/images/thumbnails/diagnose-ckd_de.jpg";
import ThumbDiagnoseCkdFR from "../../../assets/images/thumbnails/diagnose-ckd_fr.jpg";
import ThumbDiagnoseCkdIT from "../../../assets/images/thumbnails/diagnose-ckd_it.jpg";
import ThumbKdigo from "../../../assets/images/thumbnails/kdigo-org.jpg";
import ThumbKdigoCKDGuide from "../../../assets/images/thumbnails/kdigo-ckd-guide.jpg";
import ThumbTheisn from "../../../assets/images/thumbnails/theisn.org.jpg";
import ThumbNierenstiftungDE from "../../../assets/images/thumbnails/nierenstiftung.ch_de.jpg";
import ThumbNierenstiftungFR from "../../../assets/images/thumbnails/nierenstiftung.ch_fr.jpg";
import ThumbNierenstiftungIT from "../../../assets/images/thumbnails/nierenstiftung.ch_it.jpg";
import ThumbSwissnephrology from "../../../assets/images/thumbnails/swissnephrology.ch.jpg";
import ThumbNierenpatientenDE from "../../../assets/images/thumbnails/nierenpatienten.ch-de.jpg";
import ThumbNierenpatientenFR from "../../../assets/images/thumbnails/nierenpatienten.ch-fr.jpg";
import ThumbNierenpatientenIT from "../../../assets/images/thumbnails/nierenpatienten.ch-it.jpg";
import ThumbMyHealthDE from "../../../assets/images/thumbnails/nieren-leiden-leise.ch_de.jpg";
import ThumbMyHealthFR from "../../../assets/images/thumbnails/nieren-leiden-leise.ch_fr.jpg";
import ThumbMyHealthIT from "../../../assets/images/thumbnails/nieren-leiden-leise.ch_it.jpg";
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const ExternalLinks = ({ t, i18n }) => {
  const [open, setOpen] = useState(false);
  const [href, setHref] = useState("");
  const isCurrentLang = i18n.language === "de";
  const linkList = [
    {
      title: "resources.external_links.link_myhealth.text",
      image: isCurrentLang
        ? ThumbMyHealthDE
        : i18n.language === "fr"
        ? ThumbMyHealthFR
        : ThumbMyHealthIT,
      url: "resources.external_links.link_myhealth.link",
    },
    {
      title: "resources.external_links.link_1.text",
      image: isCurrentLang
        ? ThumbDiagnoseCkdDE
        : i18n.language === "fr"
        ? ThumbDiagnoseCkdFR
        : ThumbDiagnoseCkdIT,
      url: "resources.external_links.link_1.link",
    },
    {
      title: "resources.external_links.link_2.text",
      image: ThumbSwissnephrology,
      url: "resources.external_links.link_2.link",
    },
    {
      title: "resources.external_links.link_3.text",
      image: ThumbKdigo,
      url: "resources.external_links.link_3.link",
    },
    {
      title: "resources.external_links.link_4.text",
      image: ThumbKdigoCKDGuide,
      url: "resources.external_links.link_4.link",
    },
    {
      title: "resources.external_links.link_5.text",
      image: ThumbTheisn,
      url: "resources.external_links.link_5.link",
    },
    // {
    //   title: "resources.external_links.link_6.text",
    //   image: ThumbeGFRCalculator,
    //   url: "resources.external_links.link_6.link",
    // },
    {
      title: "resources.external_links.link_7.text",
      image: isCurrentLang
        ? ThumbNierenstiftungDE
        : i18n.language === "fr"
        ? ThumbNierenstiftungFR
        : ThumbNierenstiftungIT,
      url: "resources.external_links.link_7.link",
    },
    {
      title: "resources.external_links.link_8.text",
      image: isCurrentLang
        ? ThumbNierenpatientenDE
        : i18n.language === "fr"
        ? ThumbNierenpatientenFR
        : ThumbNierenpatientenIT,
      url: "resources.external_links.link_8.link",
    },
  ];

  const handleClickOpen = (e) => {
    e.preventDefault();
    setHref(e.target.href);
    setOpen(true);
  };

  const handleClose = (e, clickContinue) => {
    if (clickContinue) window.open(href, "_blank");
    setHref("");
    setOpen(false);
  };

  return (
    <div id="nutzlicheLinks" className="resources-nutzliche-links">
      <h2>{t("resources.external_links.title")}</h2>

      <Grid container spacing={2}>
        {linkList &&
          linkList.map(({ title, image, url }) => (
            <Grid item lg={3} md={4} xs={12} key={uuid()}>
              <Card elevation={3} sx={{ height: 1 }}>
                <CardMedia
                  component="img"
                  height="180"
                  image={image}
                  alt="CKD Guide"
                />
                <CardContent>
                  <p dangerouslySetInnerHTML={{ __html: t(title) }}></p>
                </CardContent>
                <CardActions>
                  <Button
                    href={t(url)}
                    onClick={handleClickOpen}
                    variant="contained"
                  >
                    {t("resources.visit_website")}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("resources.external_links.dialog_title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("resources.external_links.dialog_content")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t("general.cancel")}
          </Button>
          <Button variant="contained" onClick={(e) => handleClose(e, true)}>
            {t("general.continue")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExternalLinks;
