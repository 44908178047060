import React, { useEffect, useState } from "react";
import "./PraxisExit.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { getPraxisExitQuestionnaire } from "../../../../services/admin/praxis.service";
import PraxisExitForm from "./PraxisExitForm";

const PraxisExit = ({ praxis }) => {
  const [exitFormData, setExitFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();

  useEffect(() => {
    let didCancel = false;
    async function fetchData() {
      setLoading(true);
      const response = await getPraxisExitQuestionnaire(praxis.PraxisId);
      if (response?.status !== 200) {
        handleErrors(response.status);
      }
      if (!didCancel) setExitFormData(response.data);
      setLoading(false);
    }
    fetchData();
    return () => {
      didCancel = true;
    };
  }, [praxis]);

  const handleErrors = (status) => {
    if (status === 401) {
      redirectUserUnauthorized();
      return;
    }
    enqueueSnackbar(t("error_msg.error_contact_admin"), {
      variant: "error",
      autoHideDuration: 4000,
    });
    return;
  };

  const redirectUserUnauthorized = () => {
    sessionStorage.removeItem("currentAdmin");
    enqueueSnackbar(t("error_msg.please_log_in_again"), {
      variant: "error",
      autoHideDuration: 4000,
      onClose: () => {
        navigate(`/${i18n.language}/admin`);
      },
    });
  };

  return loading ? (
    <div>{t("general.loading")}</div>
  ) : (
    <PraxisExitForm exitFormData={exitFormData} />
  );
};

export default PraxisExit;
