import React from "react";
import "./MyAccount.scss";
import { useTranslation } from "react-i18next";
import MyAccountForm from "./MyAccountForm";
import { Grid } from "@mui/material";

const MyAccount = () => {
  const { t } = useTranslation();

  return (
    <div className="myaccount box box--white h100">
      <div className="myaccount-header box__header">
        <div>
          <h1>{t("admin.general.my_account")}</h1>
        </div>
      </div>
      <div className="myaccount-body box__body">
        <Grid container>
          <Grid item xs={12} lg={6}>
            <MyAccountForm />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default MyAccount;
