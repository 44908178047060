import React, { useState } from "react";
import "./Praxen.scss";
import { DataGrid, frFR, deDE, itIT } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import moment from "moment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

function getBaseline(params) {
  const BASELINE_MAX = 5;
  const isCompleted = params.row.Baseline ? (
    <CheckBoxIcon />
  ) : (
    <CheckBoxOutlineBlankIcon />
  );
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <div>{isCompleted}</div>
      <div>
        {params.row.BaselineNb}/{BASELINE_MAX}
      </div>
    </Stack>
  );
}

function getScreening(params) {
  const SCREENING_MAX = 10;
  const isCompleted = params.row.Screening ? (
    <CheckBoxIcon />
  ) : (
    <CheckBoxOutlineBlankIcon />
  );
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <div>{isCompleted}</div>
      <div>
        {params.row.ScreeningNb}/{SCREENING_MAX}
      </div>
    </Stack>
  );
}

const PraxenList = ({ praxen }) => {
  const [pageSize, setPageSize] = useState(10);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const localeLang =
    i18n.language === "de" ? deDE : i18n.language === "fr" ? frFR : itIT;
  const columns = [
    {
      field: "PraxisName",
      headerName: t("admin.praxen.praxen_name"),
      minWidth: 200,
      flex: 1,
    },
    {
      field: "StartDate",
      headerName: t("admin.praxen.startdate"),
      minWidth: 160,
      valueFormatter: (params) => {
        const valueFormatted = moment(params.value).format("DD.MM.YYYY");
        return `${valueFormatted}`;
      },
    },
    {
      field:
        i18n.language === "it"
          ? "TerritoryTerritoryNameIT"
          : i18n.language === "fr"
            ? "TerritoryTerritoryNameFR"
            : "TerritoryTerritoryNameDE",
      headerName: t("admin.praxen.territory"),
      minWidth: 150,
    },
    {
      field: "StatusQuo",
      headerName: t("admin.praxen.status_quo"),
      description: t("admin.praxen.status_quo"),
      minWidth: 200,
      renderCell: ({ value }) => {
        return value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
      },
    },
    {
      field: "Baseline",
      headerName: t("admin.praxen.baseline"),
      minWidth: 180,
      renderCell: getBaseline,
    },
    {
      field: "Screening",
      headerName: t("admin.praxen.screening"),
      minWidth: 180,
      renderCell: getScreening,
    },
    {
      field: "Exit",
      headerName: t("admin.praxen.exit"),
      description: t("admin.praxen.exit"),
      minWidth: 200,
      renderCell: ({ value }) => {
        return value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
      },
    },
    {
      field: "DiagnosedPatientsChange",
      headerName: t("admin.praxen.change_ckd_diagnoses"),
      description: t("admin.praxen.change_ckd_diagnoses"),
      minWidth: 240,
    },
    {
      field: "TestedPatientsChange",
      headerName: t("admin.praxen.change_patients_tested"),
      description: t("admin.praxen.change_patients_tested"),
      minWidth: 230,
    },
    {
      field: "AtRiskPatientsChange",
      headerName: t("admin.praxen.change_risk_patients"),
      description: t("admin.praxen.change_risk_patients"),
      minWidth: 240,
    },
  ];

  return (
    <div className="praxen-list">
      <DataGrid
        loading={!praxen}
        rows={praxen}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50]}
        localeText={localeLang.components.MuiDataGrid.defaultProps.localeText}
        onRowClick={(params) => {
          navigate(`${params.id}`);
        }}
      />
    </div>
  );
};

export default PraxenList;
