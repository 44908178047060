import React, { Fragment } from "react";
import { Stack, TextField, Grid } from "@mui/material";
import Moment from "react-moment";
import { Trans, useTranslation } from "react-i18next";

const PraxisScreening = ({ records, updateScreening }) => {
  const { t } = useTranslation();

  const handleChange = (event, data) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    const newRecords = {
      ...records,
      Screening: records.Screening.map((screening) => {
        if (screening.RecordDate === data.RecordDate) {
          return { ...screening, [inputName]: inputValue };
        } else {
          return screening;
        }
      }),
    };
    updateScreening(newRecords);
  };

  const Parameters = ({ index }) => {
    if (index === 0 || index === 5) {
      let woche = index === 0 ? "1" : "2";
      return (
        <Grid item md={4}>
          <div className="screening-form__parameters">
            <div>
              <strong>
                <Trans i18nKey="definition.week" values={{ week: woche }} />
              </strong>
            </div>
            <div>
              <span>{t("definition.patients_total")}</span>
            </div>
            <div>
              <span>{t("definition.patients_atrisk")}</span>
            </div>
            <div>
              <span>
                <Trans i18nKey="definition.patients_tested" />
              </span>
            </div>
            <div>
              <span>
                <Trans i18nKey="definition.patients_diagnosed" />
              </span>
            </div>
          </div>
        </Grid>
      );
    }
    return null;
  };

  return (
    <div className="screening-form">
      <h2>{t("admin.dashboard.screening")}</h2>
      <Grid container columns={14} spacing={2}>
        {records.Screening.map((data, index) => (
          <Fragment key={data.RecordDate}>
            <Parameters index={index} />

            <Grid item md={2}>
              <Stack spacing={2}>
                <div className="days">
                  <span>{data.DayOfTheWeek}</span>
                  <br />
                  <Moment format="DD.MM.YYYY">{data.RecordDate}</Moment>
                </div>
                <div>
                  <TextField
                    type="number"
                    name="Total"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => handleChange(e, data)}
                    value={data.Total ?? ""}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </div>
                <div>
                  <TextField
                    type="number"
                    name="AtRisk"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => handleChange(e, data)}
                    value={data.AtRisk ?? ""}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </div>
                <div>
                  <TextField
                    type="number"
                    name="Tested"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => handleChange(e, data)}
                    value={data.Tested ?? ""}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </div>
                <div>
                  <TextField
                    type="number"
                    name="Diagnosed"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => handleChange(e, data)}
                    value={data.Diagnosed ?? ""}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </div>
              </Stack>
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </div>
  );
};

export default PraxisScreening;
