export const yupLocale = {
  mixed: {
    default: {
      key: "form_validations.invalid",
    },
    required: {
      key: "form_validations.required",
    },
    oneOf: {
      key: "form_validations.password_not_match",
    },
    notType: ({ type }) => ({
      key: "form_validations.invalidType",
      values: { type },
    }),
  },
  string: {
    email: {
      key: "form_validations.email",
    },
    min: ({ min }) => ({
      key: "form_validations.stringMin",
      values: { min },
    }),
  },
  number: {
    min: ({ min }) => ({
      key: "form_validations.numberMin",
      values: { min },
    }),
  },
  boolean: {},
};
