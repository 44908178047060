import React, { Fragment, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Stack,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EmailIcon from "@mui/icons-material/Email";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { sendReminder } from "../../../services/admin/admin.service";

const STATUS_CREATED = 0;

const DashboardAlerts = ({ alerts }) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [praxisId, setPraxisId] = useState(null);
  const baseAdminUrl = `/${i18n.language}/admin`;

  const viewDetails = (praxisId) => {
    navigate(`${baseAdminUrl}/praxen/${praxisId}`, { replace: true });
  };

  const handleOpenPopup = (praxisId) => {
    setOpen(true);
    setPraxisId(praxisId);
  };

  const handleClosePopup = () => {
    setOpen(false);
    setPraxisId(null);
  };

  const handleConfirm = () => {
    setOpen(false);
    sendReminderEmail(praxisId);
  };

  const sendReminderEmail = async (praxisId) => {
    const response = await sendReminder(praxisId);
    setPraxisId(null);
    if (response?.status !== 200) {
      enqueueSnackbar(t("admin.dashboard.alerts.resend_email_error"), {
        variant: "error",
        autoHideDuration: 4000,
      });
      return;
    }
    enqueueSnackbar(t("admin.dashboard.alerts.resend_email_success"), {
      variant: "success",
      autoHideDuration: 4000,
    });
  };

  return (
    <Fragment>
      <div className="alert-list">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("admin.dashboard.alerts.praxis_name")}</TableCell>
              <TableCell>{t("admin.dashboard.alerts.startdatum")}</TableCell>
              <TableCell>
                {t("admin.dashboard.alerts.missing_screenings")}
              </TableCell>
              <TableCell>{t("admin.dashboard.alerts.territory")}</TableCell>
              <TableCell>{t("admin.dashboard.alerts.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alerts.map((alert) => (
              <TableRow key={alert.PraxisId}>
                <TableCell>{alert.PraxisName}</TableCell>
                <TableCell>
                  <Moment format="DD.MM.YYYY">{alert.StartDate}</Moment>
                </TableCell>
                <TableCell>{alert.MissingScreenings}</TableCell>
                <TableCell>
                  {i18n.language === "it"
                    ? alert.TerritoryTerritoryNameIT
                    : i18n.language === "fr"
                    ? alert.TerritoryTerritoryNameFR
                    : alert.TerritoryTerritoryNameDE}
                </TableCell>
                <TableCell>
                  <Stack direction="row" spacing={2}>
                    <Tooltip title={t("admin.dashboard.alerts.details")}>
                      <IconButton
                        onClick={() => viewDetails(alert.PraxisId)}
                        color="primary"
                        aria-label={t("admin.dashboard.alerts.details")}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("admin.dashboard.alerts.send_reminder")}>
                      <IconButton
                        onClick={() => handleOpenPopup(alert.PraxisId)}
                        color="primary"
                        aria-label={t("admin.dashboard.alerts.send_reminder")}
                        disabled={alert.StatusId == STATUS_CREATED}
                      >
                        <EmailIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <Dialog open={open} onClose={handleClosePopup}>
        <DialogTitle id="alert-dialog-title">
          {t("admin.dashboard.alerts.send_reminder")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("admin.dashboard.alerts.confirm_send_reminder")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClosePopup}>
            {t("general.cancel")}
          </Button>
          <Button variant="contained" onClick={handleConfirm}>
            {t("general.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DashboardAlerts;
