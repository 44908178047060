import React from "react";
import uuid from "react-uuid";
import "./Resources.scss";
import HcpBrochureDE from "../../../assets/downloadables/HCP_Brochure_de.pdf";
import HcpBrochureFR from "../../../assets/downloadables/HCP_Brochure_fr.pdf";
import HcpBrochureIT from "../../../assets/downloadables/HCP_Brochure_it.pdf";
import ThumbHcpBrochureDE from "../../../assets/images/thumbnails/HCP_Brochure_DE.jpg";
import ThumbHcpBrochureFR from "../../../assets/images/thumbnails/HCP_Brochure_FR.jpg";
import ThumbHcpBrochureIT from "../../../assets/images/thumbnails/HCP_Brochure_IT.jpg";
import PatientCounselingGuideDE from "../../../assets/downloadables/Patienten_Broschure_CKD_de.pdf";
import PatientCounselingGuideFR from "../../../assets/downloadables/Patienten_Broschure_CKD_fr.pdf";
import PatientCounselingGuideIT from "../../../assets/downloadables/Patienten_Broschure_CKD_it.pdf";
import ThumbPatientCounselingGuideDE from "../../../assets/images/thumbnails/Patient_Counseling_Guide_de.jpg";
import ThumbPatientCounselingGuideFR from "../../../assets/images/thumbnails/Patient_Counseling_Guide_fr.jpg";
import ThumbPatientCounselingGuideIT from "../../../assets/images/thumbnails/Patient_Counseling_Guide_it.jpg";
import EducationalDeckDE from "../../../assets/downloadables/DenCKDran_Educational_Deck_DE.pdf";
import EducationalDeckFR from "../../../assets/downloadables/DenCKDran_Educational_Deck_FR.pdf";
import EducationalDeckIT from "../../../assets/downloadables/DenCKDran_Educational_Deck_IT.pdf";
import ThumbEducationalDeckDE from "../../../assets/images/thumbnails/DenCKDran_Educational_Deck_DE-2.jpg";
import ThumbEducationalDeckFR from "../../../assets/images/thumbnails/DenCKDran_Educational_Deck_FR-2.jpg";
import ThumbEducationalDeckIT from "../../../assets/images/thumbnails/DenCKDran_Educational_Deck_IT-2.jpg";
import InfoFlyerHCPsDE from "../../../assets/downloadables/DenCKDran_Info_Flyer_HCPs_DE.pdf";
import InfoFlyerHCPsFR from "../../../assets/downloadables/DenCKDran_Info_Flyer_HCPs_FR.pdf";
import InfoFlyerHCPsIT from "../../../assets/downloadables/DenCKDran_Info_Flyer_HCPs_IT.pdf";
import ThumbInfoFlyerHCPsDE from "../../../assets/images/thumbnails/DenCKDran-Info-Flyer-HCPs-DE-3.jpg";
import ThumbInfoFlyerHCPsFR from "../../../assets/images/thumbnails/DenCKDran-Info-Flyer-HCPs-FR-3.jpg";
import ThumbInfoFlyerHCPsIT from "../../../assets/images/thumbnails/DenCKDran-Info-Flyer-HCPs-IT-3.jpg";
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";

const Downloadables = ({ t, i18n }) => {
  const isCurrentLang = i18n.language === "de";
  const downloadableList = [
    {
      title: "resources.downloadables.downloadable_1.text",
      image: isCurrentLang
        ? ThumbHcpBrochureDE
        : i18n.language === "fr"
        ? ThumbHcpBrochureFR
        : ThumbHcpBrochureIT,
      image_alt: "HCP Brochure",
      link: isCurrentLang
        ? HcpBrochureDE
        : i18n.language === "fr"
        ? HcpBrochureFR
        : HcpBrochureIT,
    },
    {
      title: "resources.downloadables.downloadable_2.text",
      image: isCurrentLang
        ? ThumbPatientCounselingGuideDE
        : i18n.language === "fr"
        ? ThumbPatientCounselingGuideFR
        : ThumbPatientCounselingGuideIT,
      image_alt: "Patient Counseling Guide",
      link: isCurrentLang
        ? PatientCounselingGuideDE
        : i18n.language === "fr"
        ? PatientCounselingGuideFR
        : PatientCounselingGuideIT,
    },
    /* Hide for now, until new version is approved
    {
      title: "resources.downloadables.downloadable_3.text",
      image: isCurrentLang
        ? ThumbEducationalDeckDE
        : i18n.language === "fr"
        ? ThumbEducationalDeckFR
        : ThumbEducationalDeckIT,
      image_alt: "DenCKDran Educational Deck",
      link: isCurrentLang
        ? EducationalDeckDE
        : i18n.language === "fr"
        ? EducationalDeckFR
        : EducationalDeckIT,
    },
    {
      title: "resources.downloadables.downloadable_4.text",
      image: isCurrentLang
        ? ThumbInfoFlyerHCPsDE
        : i18n.language === "fr"
        ? ThumbInfoFlyerHCPsFR
        : ThumbInfoFlyerHCPsIT,
      image_alt: "DenCKDran Info Flyer HCPs",
      link: isCurrentLang
        ? InfoFlyerHCPsDE
        : i18n.language === "fr"
        ? InfoFlyerHCPsFR
        : InfoFlyerHCPsIT,
    },
    */
  ];

  return (
    <div id="materialien" className="resources-materialien">
      <h2>{t("resources.downloadables.title")}</h2>

      <Grid container spacing={2}>
        {downloadableList &&
          downloadableList.map((data) => (
            <Grid item lg={3} md={4} xs={12} key={uuid()}>
              <Card elevation={3}>
                <CardMedia
                  component="img"
                  height="180"
                  image={data.image}
                  alt={data.image_alt}
                />
                <CardContent>
                  <p>{t(data.title)}</p>
                </CardContent>
                <CardActions>
                  <Button href={data.link} target="_blank" variant="contained">
                    {t("resources.download")}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default Downloadables;
