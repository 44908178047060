import axios from "axios";

const domain = process.env.REACT_APP_DOMAIN;
const baseURL = `${domain}/AZService/AZLoginService.svc`;

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

function loginAdmin(credentials) {
  return instance
    .post(`/AZLogin`, credentials)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function logoutAdmin() {
  return instance
    .post(`/Logout`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function resetPasswordEmail(email) {
  return instance
    .post(`/SendPasswordResetEmail`, email)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function resetPassword(data) {
  return instance
    .post(`/ResetPassword`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

export { loginAdmin, logoutAdmin, resetPassword, resetPasswordEmail };
