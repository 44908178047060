import React from "react";
import "./Layout.scss";
import HeaderAdmin from "../header/HeaderAdmin";
import Footer from "../footer/Footer";
import MenuAdmin from "../menu/MenuAdmin";
import { Outlet } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const LayoutAdmin = () => {
  const { t } = useTranslation();

  return (
    <div className="layout">
      <HelmetProvider>
        <Helmet>
          <title>{t("general.title")}</title>
        </Helmet>
      </HelmetProvider>

      <HeaderAdmin />
      <MenuAdmin />
      <main className="main">
        <div className="main-container">
          <div className="main-content">
            <Outlet />
          </div>
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default LayoutAdmin;
