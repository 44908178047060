import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  getStatistics,
  getAlerts,
} from "../../../services/admin/admin.service";

import DashboardAlerts from "./DashboardAlerts";
import DashboardStats from "./DashboardStats";
import { CircularProgress } from "@mui/material";

const Dashboard = () => {
  const [stats, setStats] = useState(null);
  const [alerts, setAlerts] = useState([]);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const resStats = await getStatistics();
      const resAlerts = await getAlerts();

      if (resStats?.status !== 200) handleErrors(resStats.status);
      if (resAlerts?.status !== 200) handleErrors(resAlerts.status);

      setStats(resStats.data);
      setAlerts(resAlerts.data);
    }
    fetchData();
  }, []);

  const handleErrors = (status) => {
    if (status === 401) {
      redirectUserUnauthorized();
    } else {
      enqueueSnackbar(t("error_msg.error_contact_admin"), {
        variant: "error",
        autoHideDuration: 4000,
      });
      return;
    }
  };

  const redirectUserUnauthorized = () => {
    sessionStorage.removeItem("currentAdmin");
    enqueueSnackbar(t("error_msg.please_log_in_again"), {
      variant: "error",
      autoHideDuration: 4000,
      onClose: () => {
        navigate(`/${i18n.language}/admin`);
      },
    });
  };

  return (
    <div className="dashboard box--separate">
      <div className="dashboard-body">
        {stats ? <DashboardStats stats={stats} /> : <CircularProgress />}

        {alerts ? (
          <DashboardAlerts alerts={alerts} />
        ) : (
          <div>{t("general.loading")}</div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
