export const initialState = {
  baseline: [],
  loading: true,
  error: "",
  submitting: false,
};

export const formReducer = (state, action) => {
  switch (action.type) {
    case "GET_SUCCESS":
      return {
        baseline: action.payload,
        loading: false,
        error: "",
      };
    case "GET_ERROR":
      return {
        baseline: [],
        loading: false,
        error: action.error,
      };
    case "ON_CHANGE":
      const inputName = action.payload.event.target.name;
      const inputValue = action.payload.event.target.value;
      return {
        baseline: state.baseline.map((baseline) => {
          if (baseline.RecordDate === action.payload.data.RecordDate) {
            return { ...baseline, [inputName]: inputValue };
          } else {
            return baseline;
          }
        }),
        loading: false,
        error: "",
      };
    case "ON_SUBMIT":
      return {
        ...state,
        submitting: true,
      };
    case "SUBMIT_COMPLETE":
      return {
        ...state,
        submitting: false,
      };
    default:
      return state;
  }
};
