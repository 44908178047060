import axios from "axios";

const domain = process.env.REACT_APP_DOMAIN;
const baseURL = `${domain}/PraxisService/LoginService.svc`;

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

function loginUser(credentials) {
  return instance
    .post("/PraxisLogin", JSON.stringify(credentials))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function logoutUser() {
  return instance
    .post("/Logout")
    .then((response) => {
      sessionStorage.removeItem("currentPraxis");
      sessionStorage.removeItem("viewStatsProgramPopup");
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function activatePraxis(data) {
  return instance
    .post("/ActivatePraxis", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function forgotPassword(email) {
  return instance
    .post("/SendPasswordResetEmail", email)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

function resetPassword(data) {
  return instance
    .post("/ResetPassword", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response);
        return error.response;
      }
      console.error(error);
      return error;
    });
}

export { loginUser, logoutUser, activatePraxis, forgotPassword, resetPassword };
