import React from "react";
import "./ExitFragebogen.scss";
import ExitForm from "./ExitForm";
import { useTranslation } from "react-i18next";

const ExitFragebogen = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="exit-view box box--white">
      <div className="exit-wrapper box__header">
        <div>
          <h1>{t("exit.header")}</h1>
        </div>
      </div>

      <ExitForm t={t} i18n={i18n} />
    </div>
  );
};

export default ExitFragebogen;
