import React from "react";
import "./Menu.scss";
import logo from "../../assets/images/denck-dran-logo.svg";
import logoFR from "../../assets/images/denck-dran-logo-fr.svg";
import logoIT from "../../assets/images/denck-dran-logo-it.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

const MenuAdmin = () => {
  const { t, i18n } = useTranslation();
  const baseAdminUrl = `/${i18n.language}/admin`;
  const activeClassName = "is-active";
  const logoSrc = i18n.language === "de" ? logo : i18n.language === "fr" ? logoFR : logoIT;

  return (
    <nav className="menu">
      <div className="menu-container">
        <div className="menu-top">
          <img src={logoSrc} alt="Denck Dran" className="header__logo" />
        </div>
        <ul className="menu-list">
          <li className="menu-list__item">
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
              to={baseAdminUrl + "/dashboard"}
            >
              <DashboardIcon />
              <span>{t("navigation.dashboard")}</span>
            </NavLink>
          </li>
          <li className="menu-list__item">
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
              to={baseAdminUrl + "/praxen"}
            >
              <LocalHospitalIcon />
              <span>{t("navigation.praxen")}</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default MenuAdmin;
