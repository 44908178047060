import React from "react";
import "./MyAccount.scss";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button, Stack } from "@mui/material";
import { ErrorMessage } from "../../../components/form";
import { changePassword } from "../../../services/admin/admin.service";

const MyAccountForm = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const schema = yup.object().shape({
    oldPassword: yup.string().required(),
    newPassword: yup.string().required().min(8),
    confirmNewPassword: yup
      .string()
      .required()
      .oneOf(
        [yup.ref("newPassword")],
        t("form_validations.password_not_match")
      ),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async ({ oldPassword, newPassword, confirmNewPassword }) => {
    if (
      oldPassword.length === 0 ||
      newPassword.length === 0 ||
      confirmNewPassword.length === 0
    )
      return;

    const response = await changePassword({ oldPassword, newPassword });

    handleWsReponse(response);
  };

  const handleWsReponse = (response) => {
    if (response?.status !== 200) {
      enqueueSnackbar(t("praxis_details.password.error_msg"), {
        variant: "error",
        autoHideDuration: 5000,
      });
      return;
    }
    if (!response?.data) {
      enqueueSnackbar(t("praxis_details.password.error_msg"), {
        variant: "error",
        autoHideDuration: 5000,
      });
      return;
    }
    enqueueSnackbar(t("praxis_details.password.success_msg"), {
      variant: "success",
      autoHideDuration: 5000,
    });
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="myaccount-form">
      <fieldset disabled={isSubmitting}>
        <Stack direction="column" spacing={2}>
          <div>
            <Controller
              name="oldPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  error={Boolean(errors.oldPassword)}
                  type="password"
                  label={t("general.old_password")}
                  {...field}
                />
              )}
            />
            {errors.oldPassword && (
              <ErrorMessage error={errors.oldPassword?.message} />
            )}
          </div>
          <div>
            <Controller
              name="newPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  error={Boolean(errors.newPassword)}
                  type="password"
                  label={t("general.new_password")}
                  {...field}
                />
              )}
            />
            {errors.newPassword && (
              <ErrorMessage error={errors.newPassword?.message} />
            )}
          </div>
          <div>
            <Controller
              name="confirmNewPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  error={Boolean(errors.confirmNewPassword)}
                  type="password"
                  label={t("general.confirm_new_password")}
                  {...field}
                />
              )}
            />
            {errors.confirmNewPassword && (
              <ErrorMessage error={errors.confirmNewPassword?.message} />
            )}
          </div>
          <div className="myaccount-form__actions">
            <Button type="submit" variant="contained" color="secondary">
              {t("praxis_details.update_password")}
            </Button>
          </div>
        </Stack>
      </fieldset>
    </form>
  );
};

export default MyAccountForm;
