import React, { Fragment, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SendIcon from "@mui/icons-material/Send";
import { IconButton, Stack, CircularProgress, Button } from "@mui/material";
import {
  getPraxis,
  resendActivationEmail,
} from "../../../services/admin/admin.service";
import "./Praxis.scss";
import PraxisTabs from "./PraxisTabs";
import moment from "moment";
import ConfirmResendEmailActivation from "./ConfirmResendEmailActivation";

const STATUS_CREATED = 0;

const Praxis = () => {
  const [praxis, setPraxis] = useState(null);
  let { praxisId } = useParams();
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const response = await getPraxis(praxisId);
      if (response?.status !== 200) {
        handleErrors(response.status);
        return;
      }
      const praxisData = response.data;
      praxisData.StartDate = convertDate(praxisData.StartDate);
      setPraxis(praxisData);
    }
    fetchData();
  }, [praxisId]);

  const convertDate = (date) => {
    return moment(date).format("yyyy-MM-DD");
  };

  const redirectUserUnauthorized = () => {
    sessionStorage.removeItem("currentAdmin");
    enqueueSnackbar(t("error_msg.please_log_in_again"), {
      variant: "error",
      autoHideDuration: 4000,
      onClose: () => {
        navigate(`/${i18n.language}/admin`);
      },
    });
  };

  const handleErrors = (status) => {
    if (status === 401) {
      redirectUserUnauthorized();
    }
    enqueueSnackbar(t("error_msg.error_contact_admin"), {
      variant: "error",
      autoHideDuration: 4000,
    });
  };

  const handleClickResendEmail = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    if (value) handleResendActivationEmail();
  };

  const handleResendActivationEmail = async () => {
    const response = await resendActivationEmail(praxisId);

    if (response?.status !== 200) {
      enqueueSnackbar(t("admin.praxis_activation.resend_activation_email_error"), {
        variant: "error",
        autoHideDuration: 4000,
      });
      return;
    }
    enqueueSnackbar(t("admin.praxis_activation.resend_activation_email_success"), {
      variant: "success",
      autoHideDuration: 4000,
    });
  };

  return praxis ? (
    <Fragment>
      <div className="praxis box box--white h100">
        <div className="praxis-header box__header">
          <div>
            <Stack direction="row" spacing={1}>
              <IconButton
                aria-label="back to praxen"
                color="secondary"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIosNewIcon sx={{ fontSize: 28 }} />
              </IconButton>
              <h1>{praxis.DisplayName}</h1>
            </Stack>
          </div>
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            disabled={praxis.StatusId !== STATUS_CREATED}
            onClick={handleClickResendEmail}
          >
            {t("admin.praxis_activation.resend_activation_email")}
          </Button>
        </div>
        <div className="praxis-body box__body">
          <PraxisTabs praxis={praxis} />
        </div>
      </div>
      <ConfirmResendEmailActivation open={open} onClose={handleClose} />
    </Fragment>
  ) : (
    <CircularProgress color="primary" />
  );
};

export default Praxis;
