import React, { useReducer, useEffect } from "react";
import {
  Stack,
  TextField,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getBaseline, saveBaseline, validateBaseline } from "./BaselineAPI";
import {
  initialState,
  formReducer,
} from "../../../store/baseline/baseline.reducer";
import { useAuth } from "../../../store/auth/auth.context";
import { Trans } from "react-i18next";

const STATUS_ID = 2;

const BaselineForm = ({ t, i18n }) => {
  const [formData, setFormData] = useReducer(formReducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { authState, dispatch } = useAuth();

  const DefinitionBox = () => {
    return (
      <div className="definition-box definition-box--orange">
        <p>
          <em>{t("definition.box.title")}</em>
        </p>
        <ol>
          <li>
            <Trans i18nKey="definition.box.line1" />
          </li>
          <li>
            <Trans i18nKey="definition.box.line2" />
          </li>
          <li>
            <Trans i18nKey="definition.box.line3" />
          </li>
          <li>
            <div
              dangerouslySetInnerHTML={{
                __html: t("definition.box.line4", {
                  interpolation: { escapeValue: false },
                }),
              }}
            />
          </li>
        </ol>
      </div>
    );
  };

  useEffect(() => {
    async function fetchData() {
      const response = await getBaseline();
      if (response.status !== 200) {
        redirectUserUnauthorized();
        return;
      }
      setFormData({ type: "GET_SUCCESS", payload: response.data });
    }
    fetchData();
  }, []);

  const redirectUserUnauthorized = () => {
    sessionStorage.removeItem("currentPraxis");
    enqueueSnackbar(t("error_msg.please_log_in_again"), {
      variant: "error",
      autoHideDuration: 4000,
    });
    navigate("/" + i18n.language);
  };

  const handleChange = (event, data) => {
    setFormData({ type: "ON_CHANGE", payload: { event, data } });
  };

  const cacheInformation = () => {
    setFormData({ type: "ON_SUBMIT" });

    saveBaseline(formData.baseline).then((response) => {
      if (response.status !== 200) {
        if (response.status === 401) {
          redirectUserUnauthorized();
          return;
        }
        setFormData({ type: "SUBMIT_COMPLETE" });
        enqueueSnackbar(t("baseline.error_msg"), {
          variant: "error",
          autoHideDuration: 4000,
        });
        return;
      }

      setFormData({ type: "SUBMIT_COMPLETE" });
      enqueueSnackbar(t("baseline.cache_success_msg"), {
        variant: "success",
        autoHideDuration: 4000,
      });
    });
  };

  const submitInformation = () => {
    setFormData({ type: "ON_SUBMIT" });

    validateBaseline(formData.baseline).then((response) => {
      if (response.status !== 200) {
        if (response.status === 401) {
          redirectUserUnauthorized();
          return;
        }
        setFormData({ type: "SUBMIT_COMPLETE" });
        enqueueSnackbar(t("baseline.error_msg"), {
          variant: "error",
          autoHideDuration: 4000,
        });
        return;
      }

      setFormData({ type: "SUBMIT_COMPLETE" });
      enqueueSnackbar(t("baseline.success_msg"), {
        variant: "success",
        autoHideDuration: 4000,
        onClose: () => {
          navigate("/" + i18n.language + "/screening");
        },
      });
      dispatch({ type: "LOGIN_SUCCESS", payload: response.data });
      sessionStorage.setItem("currentPraxis", JSON.stringify(response.data));
    });
  };

  const handleDisable = () => {
    if (formData.baseline.length === 0) return;
    const areAllFilled = formData.baseline.some((baseline) => {
      for (var item in baseline) {
        if (baseline[item] == null || baseline[item].length === 0) {
          return true;
        }
      }
      return false;
    });
    return areAllFilled;
  };

  const isCacheDisable =
    formData.submitting || authState.praxis.StatusId !== STATUS_ID;
  const isSubmitDisable =
    formData.submitting ||
    handleDisable() ||
    authState.praxis.StatusId !== STATUS_ID;

  return (
    <>
      <div className="baseline-body box__body">
        <DefinitionBox />
        <div className="baseline-form">
          <Grid container columns={14} spacing={2}>
            <Grid item md={4}>
              <div className="parameters">
                <div>
                  <span>{t("definition.patients_total")}</span>
                </div>
                <div>
                  <span>{t("definition.patients_atrisk")}</span>
                </div>
                <div>
                  <span>
                    <Trans i18nKey="definition.patients_tested" />
                  </span>
                </div>
                <div>
                  <span>
                    <Trans i18nKey="definition.patients_diagnosed" />
                  </span>
                </div>
              </div>
            </Grid>

            {formData.loading ? (
              <div className="baseline-loader">
                <CircularProgress />
              </div>
            ) : (
              formData.baseline.map((data) => (
                <Grid item md={2} key={data.RecordDate}>
                  <Stack spacing={2}>
                    <div className="days">
                      <span>{data.DayOfTheWeek}</span>
                      <br />
                      <Moment format="DD.MM.YYYY">{data.RecordDate}</Moment>
                    </div>
                    <div>
                      <TextField
                        type="number"
                        name="Total"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => handleChange(e, data)}
                        value={data.Total ?? ""}
                        InputProps={{ inputProps: { min: 0 } }}
                        disabled={isCacheDisable}
                      />
                    </div>
                    <div>
                      <TextField
                        type="number"
                        name="AtRisk"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => handleChange(e, data)}
                        value={data.AtRisk ?? ""}
                        InputProps={{ inputProps: { min: 0 } }}
                        disabled={isCacheDisable}
                      />
                    </div>
                    <div>
                      <TextField
                        type="number"
                        name="Tested"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => handleChange(e, data)}
                        value={data.Tested ?? ""}
                        InputProps={{ inputProps: { min: 0 } }}
                        disabled={isCacheDisable}
                      />
                    </div>
                    <div>
                      <TextField
                        type="number"
                        name="Diagnosed"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => handleChange(e, data)}
                        value={data.Diagnosed ?? ""}
                        InputProps={{ inputProps: { min: 0 } }}
                        disabled={isCacheDisable}
                      />
                    </div>
                  </Stack>
                </Grid>
              ))
            )}
          </Grid>
        </div>
      </div>

      <div className="baseline-footnote">
        <p>{t("baseline.footnote")}</p>
      </div>

      <div className="baseline-footer box__footer">
        <Stack direction="row" spacing={2}>
          <Button
            onClick={cacheInformation}
            variant="contained"
            color="secondary"
            disabled={isCacheDisable}
          >
            {t("baseline.cache_info")}
          </Button>
          <Button
            onClick={submitInformation}
            variant="contained"
            color="secondary"
            disabled={isSubmitDisable}
          >
            {t("baseline.submit_info")}
          </Button>
        </Stack>
      </div>
    </>
  );
};

export default BaselineForm;
