import React from "react";
import uuid from "react-uuid";
import "./Resources.scss";
import DenckdranNewsletterDE from "../../../assets/downloadables/DenCKDran_Newsletter_V1_July_2022_de.pdf";
import DenckdranNewsletterFR from "../../../assets/downloadables/DenCKDran_Newsletter_V1_July_2022_fr.pdf";
import DenckdranNewsletterIT from "../../../assets/downloadables/DenCKDran_Newsletter_V1_July_2022_it.pdf";
import DenckdranNewsletterV2DE from "../../../assets/downloadables/DenCKDran_Newsletter_V2_November_2022_de.pdf";
import DenckdranNewsletterV2FR from "../../../assets/downloadables/DenCKDran_Newsletter_V2_November_2022_fr.pdf";
import DenckdranNewsletterV2IT from "../../../assets/downloadables/DenCKDran_Newsletter_V2_November_2022_it.pdf";
import DenckdranNewsletterV3DE from "../../../assets/downloadables/DenCKDran_Newsletter_V3_March_2023_de.pdf";
import DenckdranNewsletterV3FR from "../../../assets/downloadables/DenCKDran_Newsletter_V3_March_2023_fr.pdf";
import DenckdranNewsletterV3IT from "../../../assets/downloadables/DenCKDran_Newsletter_V3_March_2023_it.pdf";
import ThumbNewsletterDE from "../../../assets/images/thumbnails/DenCKDran-Newsletter-de.jpg";
import ThumbNewsletterFR from "../../../assets/images/thumbnails/DenCKDran-Newsletter-fr.jpg";
import ThumbNewsletterIT from "../../../assets/images/thumbnails/DenCKDran-Newsletter-it.jpg";
import ThumbNewsletterV3DE from "../../../assets/images/thumbnails/DenCKDran-Newsletter-v3-de.jpg";
import ThumbNewsletterV3FR from "../../../assets/images/thumbnails/DenCKDran-Newsletter-v3-fr.jpg";
import ThumbNewsletterV3IT from "../../../assets/images/thumbnails/DenCKDran-Newsletter-v3-it.jpg";
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";

const Newsletters = ({ t, i18n }) => {
  const isCurrentLang = i18n.language === "de";
  const newsletterList = [
    {
      title: "resources.newsletters.newsletter_3.text",
      image: isCurrentLang
        ? ThumbNewsletterV3DE
        : i18n.language === "fr"
        ? ThumbNewsletterV3FR
        : ThumbNewsletterV3IT,
      image_alt: "DenCKDran Newsletter V3",
      link: isCurrentLang
        ? DenckdranNewsletterV3DE
        : i18n.language === "fr"
        ? DenckdranNewsletterV3FR
        : DenckdranNewsletterV3IT,
    },
    {
      title: "resources.newsletters.newsletter_2.text",
      image: isCurrentLang
        ? ThumbNewsletterDE
        : i18n.language === "fr"
        ? ThumbNewsletterFR
        : ThumbNewsletterIT,
      image_alt: "DenCKDran Newsletter V2",
      link: isCurrentLang
        ? DenckdranNewsletterV2DE
        : i18n.language === "fr"
        ? DenckdranNewsletterV2FR
        : DenckdranNewsletterV2IT,
    },
    {
      title: "resources.newsletters.newsletter_1.text",
      image: isCurrentLang
        ? ThumbNewsletterDE
        : i18n.language === "fr"
        ? ThumbNewsletterFR
        : ThumbNewsletterIT,
      image_alt: "DenCKDran Newsletter",
      link: isCurrentLang
        ? DenckdranNewsletterDE
        : i18n.language === "fr"
        ? DenckdranNewsletterFR
        : DenckdranNewsletterIT,
    },
  ];

  return (
    <div id="newsletters" className="resources-materialien">
      <h2>{t("resources.newsletters.title")}</h2>

      <Grid container spacing={2}>
        {newsletterList &&
          newsletterList.map((data) => (
            <Grid item lg={3} md={4} xs={12} key={uuid()}>
              <Card elevation={3}>
                <CardMedia
                  component="img"
                  height="180"
                  image={data.image}
                  alt={data.image_alt}
                />
                <CardContent>
                  <p>{t(data.title)}</p>
                </CardContent>
                <CardActions>
                  <Button href={data.link} target="_blank" variant="contained">
                    {t("resources.download")}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default Newsletters;
