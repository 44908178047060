import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";


i18n.on('languageChanged', function (lng) {
  //Check url is corresponding to language and redirect to /de if no local in the URL
  if (lng === i18n.options.fallbackLng[0]) {
    if (!window.location.pathname.includes('/' + i18n.options.fallbackLng[0])) {
      if (window.location.pathname.includes('/' + i18n.options.whitelist[1])) {
        const newUrl = window.location.pathname.replace('/' + i18n.options.whitelist[1], '/' + i18n.options.fallbackLng[0])
        window.location.replace(newUrl);
      }
      else {
        const newUrl = window.location.pathname.replace('/', '/' + i18n.options.fallbackLng[0])
        window.location.replace(newUrl);
      }
    }
  }
})


i18n
  .use(detector)
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    fallbackLng: "de",
    whitelist: ['de','fr','it'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      // order and from where user language should be detected
      order: ['path'],
      caches: [],
      
      // keys or params to lookup language from
      lookupFromPathIndex: 0,

      checkWhitelist: true
    }
  });

export default i18n;
