import React, { useEffect } from "react";
import "./Login.scss";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  TextField,
  Stack,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { loginUser } from "../../../services/login.service";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../../store/auth/auth.context";

const ErrorMessage = ({ errorMsg }) => {
  if (errorMsg) return <Alert severity="error">{errorMsg}</Alert>;
  return null;
};

const LoginForm = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { authState, dispatch } = useAuth();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const loggedInPraxis = sessionStorage.getItem("currentPraxis")
      ? JSON.parse(sessionStorage.getItem("currentPraxis"))
      : "";
    if (loggedInPraxis)
      userRedirection(loggedInPraxis.Language, loggedInPraxis.StatusId);
  }, []);

  const onSubmit = async ({ email, password }) => {
    dispatch({ type: "REQUEST_LOGIN" });

    const response = await loginUser({
      email,
      password,
    });

    handleWsReponse(response);
  };

  const handleWsReponse = (response) => {
    if (response.status !== 200) {
      dispatch({ type: "LOGIN_ERROR", error: t("error_msg.access_denied") });
      return;
    }
    dispatch({ type: "LOGIN_SUCCESS", payload: response.data });
    sessionStorage.setItem("currentPraxis", JSON.stringify(response.data));
    userRedirection(response.data.Language, response.data.StatusId);
  };

  const userRedirection = (lang, statusId) => {
    lang = lang.toLowerCase();
    if (i18n.Language !== lang) {
      i18n.changeLanguage(lang);
    }
    switch (statusId) {
      case 1:
        navigate("/" + lang.toLowerCase() + "/status-quo");
        break;
      case 2:
        navigate("/" + lang.toLowerCase() + "/baseline");
        break;
      case 3:
        navigate("/" + lang.toLowerCase() + "/screening");
        break;
      case 4:
        navigate("/" + lang.toLowerCase() + "/exit-fragebogen");
        break;
      case 5:
        navigate("/" + lang.toLowerCase() + "/ressourcen");
        break;
      default:
        navigate("/" + lang.toLowerCase() + "/praxis-details");
    }
  };

  userRedirection.propTypes = {
    lang: PropTypes.string,
    statusId: PropTypes.number,
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="login-form">
        <Stack direction="column" spacing={3}>
          {authState.errorMessage && (
            <ErrorMessage errorMsg={authState.errorMessage} />
          )}
          <div>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  error={Boolean(errors.email)}
                  type="email"
                  label="Email"
                  color="secondary"
                  sx={{ bgcolor: "white.main", borderRadius: "8px" }}
                  {...field}
                />
              )}
              rules={{
                required: t("error_msg.email_required"),
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t("error_msg.email_not_valid"),
                },
              }}
            />
            {errors.email && (
              <p className="helper-text helper-text--error">
                {errors.email.message}
              </p>
            )}
          </div>
          <div>
            <div>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    error={Boolean(errors.password)}
                    type="password"
                    label={t("general.password")}
                    color="secondary"
                    sx={{ bgcolor: "white.main", borderRadius: "8px" }}
                    {...field}
                  />
                )}
                rules={{ required: t("error_msg.password_required") }}
              />
              {errors.password && (
                <p className="helper-text helper-text--error">
                  {errors.password.message}
                </p>
              )}
            </div>

            <div className="login__forgot">
              <Link className="login__forgot-link" to="forgotpassword">
                {t("login.forgot_password")}
              </Link>
            </div>
          </div>

          <Button
            type="submit"
            variant="contained"
            color="secondary"
            aria-label="Anmelden"
            disabled={authState.loading}
          >
            {t("login.log_in")}
          </Button>
        </Stack>
      </form>
      <Backdrop
        sx={{ marginTop: 0, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={authState.loading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </>
  );
};

export default LoginForm;
