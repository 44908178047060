import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const ConfirmResendEmailActivation = (props) => {
  const { onClose, open } = props;
  const { t } = useTranslation();

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = () => {
    onClose(true);
  };

  return (
    <Dialog open={open}>
      <DialogTitle id="alert-dialog-title">
        {t("admin.praxis_activation.resend_activation_email")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("admin.praxis_activation.resend_activation_email_msg")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          {t("general.cancel")}
        </Button>
        <Button variant="contained" onClick={handleConfirm}>
          {t("general.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmResendEmailActivation;
