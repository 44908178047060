import React, { useState, useEffect } from "react";
import "./Header.scss";
import { Menu, MenuItem, Avatar } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonIcon from "@mui/icons-material/Person";
import { logoutUser } from "../../services/login.service";
import { getTerritoriesList } from "../../services/praxis/praxis.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../store/auth";
import HeaderContact from "./HeaderContact";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [territory, setTerritory] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { authState, dispatch } = useAuth();

  const userName = !authState.praxis ? null : authState.praxis.PraxisName;
  const territoryId = !authState.praxis ? null : authState.praxis.TerritoryId;

  useEffect(() => {
    async function fetchData() {
      const response = await getTerritoriesList();
      const foundTerritory = await getTerritoryById(response.data);
      setTerritory(foundTerritory);
    }
    fetchData();
  }, [territoryId]);

  const getTerritoryById = (territories) => {
    if (territories && territories.length === 0) return null;
    const territory = territories.find(
      (territory) => territory.TerritoryId === territoryId
    );
    return territory;
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logoutUser().then((response) => {
      dispatch({ type: "LOGOUT" });
      navigate("/" + i18n.language);
    });
    handleClose();
  };

  return (
    <header className="header">
      <div className="header-wrapper">
        <HeaderContact t={t} territory={territory} />
        <div>
          <div className="header__user" onClick={handleOpenMenu}>
            <Avatar>
              <PersonIcon />
            </Avatar>
            <span>{userName}</span>
            <KeyboardArrowDownIcon color="primary" />
          </div>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 1,
              sx: {
                width: 160,
              },
            }}
          >
            <MenuItem onClick={handleLogout}>{t("general.logout")}</MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default Header;
