import React from "react";
import "./PraxisDetails.scss";
import { Grid } from "@mui/material";
import PraxisForm from "./PraxisForm";
import NewPasswordForm from "./NewPasswordForm";
import { useTranslation } from "react-i18next";

const PraxisDetails = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="details box--separate">
      <div className="details-header box__header box__header--separate">
        <div>
          <h1>{t("praxis_details.header")}</h1>
        </div>
      </div>
      <div className="details-body">
        <Grid container spacing={2}>
          <Grid item lg={6} md={12}>
            <div className="box box--white">
              <div className="box__header">
                <h2>{t("praxis_details.praxis")}</h2>
              </div>
              <PraxisForm t={t} i18n={i18n} />
            </div>
          </Grid>
          <Grid item lg={6} md={12}>
            <div className="box box--white h100">
              <NewPasswordForm t={t} />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PraxisDetails;
