import React, { useState, useEffect } from "react";
import "./PraxisStats.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Stack, Grid } from "@mui/material";
import ProgressBar from "../../../../components/progressbar/ProgressBar";
import StatisticsBox from "../../../../components/StatisticsBox/StatisticsBox";
import { getPraxisStatistics } from "../../../../services/admin/praxis.service";

const legends = [
  {
    id: 1,
    label: "admin.dashboard.nb_diagnosed_patients",
    color: "primary",
  },
  {
    id: 2,
    label: "admin.dashboard.nb_tested_patients",
    color: "secondary",
  },
  {
    id: 3,
    label: "admin.dashboard.nb_risk_patients",
    color: "lightblue",
  },
];

const NoStatistics = ({ t }) => {
  return (
    <div className="praxis-stats__no-data">
      {t("general.no_data_available")}
    </div>
  );
};

const PraxisStats = ({ praxis }) => {
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const response = await getPraxisStatistics(praxis.PraxisId);
      setIsLoading(false);
      if (response?.status !== 200) {
        handleErrors(response.status);
        return;
      }
      setStats(response.data);
    }
    fetchData();
  }, [praxis]);

  const handleErrors = (status) => {
    if (status === 401) {
      redirectUserUnauthorized();
      return;
    }
    if (status === 403) {
      return;
    }
    enqueueSnackbar(t("error_msg.error_contact_admin"), {
      variant: "error",
      autoHideDuration: 4000,
    });
    return;
  };

  const redirectUserUnauthorized = () => {
    sessionStorage.removeItem("currentAdmin");
    enqueueSnackbar(t("error_msg.please_log_in_again"), {
      variant: "error",
      autoHideDuration: 4000,
      onClose: () => {
        navigate(`/${i18n.language}/admin`);
      },
    });
  };

  return isLoading ? (
    <div>{t("general.loading")}</div>
  ) : stats ? (
    <>
      <Grid container spacing={2}>
        <Grid item md={12} lg={6}>
          <div className="card">
            <div className="card__header">
              <h2>{t("admin.dashboard.baseline")}</h2>
            </div>
            <div className="card__content">
              <Stack
                direction="column"
                spacing={{ xs: 2, sm: 4, xl: 6 }}
                sx={{ width: 1 }}
              >
                <div className="progress">
                  <ProgressBar
                    percentage={stats.Baseline.DiagnosedPatients.Percentage}
                    number={stats.Baseline.DiagnosedPatients.Nb}
                    bgColor="var(--clr-orange)"
                    zIndex="4"
                  />
                  <ProgressBar
                    percentage={stats.Baseline.TestedPatients.Percentage}
                    number={stats.Baseline.TestedPatients.Nb}
                    bgColor="var(--clr-blue)"
                    zIndex="3"
                  />
                  <ProgressBar
                    isTotal={true}
                    percentage={stats.Baseline.RiskPatients.Percentage}
                    number={stats.Baseline.RiskPatients.Nb}
                    bgColor="var(--clr-lightblue)"
                    zIndex="2"
                  />
                </div>
                <div className="legend-baseline-screening">
                  <Grid container spacing={2}>
                    {legends.map((legend) => (
                      <Grid item xs={4} key={legend.id}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <span
                            className={`legend__color legend__color--${legend.color}`}
                          ></span>
                          <span className="legend__label">
                            {t(legend.label)}
                          </span>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Stack>
            </div>
          </div>
        </Grid>
        <Grid item md={12} lg={6}>
          <div className="card">
            <div className="card__header">
              <h2>{t("admin.dashboard.screening")}</h2>
            </div>
            <div className="card__content">
              <Stack
                direction="column"
                spacing={{ xs: 2, sm: 4, xl: 6 }}
                sx={{ width: 1 }}
              >
                <div className="progress">
                  <ProgressBar
                    zIndex="4"
                    percentage={stats.Screening.DiagnosedPatients.Percentage}
                    number={stats.Screening.DiagnosedPatients.Nb}
                    bgColor="var(--clr-orange)"
                  />
                  <ProgressBar
                    zIndex="3"
                    percentage={stats.Screening.TestedPatients.Percentage}
                    number={stats.Screening.TestedPatients.Nb}
                    bgColor="var(--clr-blue)"
                  />
                  <ProgressBar
                    zIndex="2"
                    isTotal={true}
                    percentage={stats.Screening.RiskPatients.Percentage}
                    number={stats.Screening.RiskPatients.Nb}
                    bgColor="var(--clr-lightblue)"
                  />
                </div>
                <div className="legend-baseline-screening">
                  <Grid container spacing={2}>
                    {legends.map((legend) => (
                      <Grid item xs={4} key={legend.id}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <span
                            className={`legend__color legend__color--${legend.color}`}
                          ></span>
                          <span className="legend__label">
                            {t(legend.label)}
                          </span>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Stack>
            </div>
          </div>
        </Grid>
        <Grid item md={12} lg={6}>
          <div className="dashboard-stat-box dashboard-stat-box--multiline dashboard-stat-box--lightblue">
            <div className="dashboard-stat-box--line">
              <span className="dashboard-stat-box__label">
                {t("admin.dashboard.nb_total_patients")}
              </span>
              <span className="dashboard-stat-box__result">
                {stats.PatientsAtRisksStats.TotalPatients}
              </span>
            </div>
            <div className="dashboard-stat-box--line">
              <span className="dashboard-stat-box__label">
                {t("admin.dashboard.nb_risk_patients")}
              </span>
              <span className="dashboard-stat-box__result">
                {stats.PatientsAtRisksStats.TotalPatientsAtRisk}
              </span>
            </div>
            <div className="dashboard-stat-box--line">
              <span className="dashboard-stat-box__label">
                {t("admin.dashboard.percentage_risk_patients")}
              </span>
              <span className="dashboard-stat-box__result">
                {stats.PatientsAtRisksStats.PercentagePatientsAtRisk}%
              </span>
            </div>
          </div>
        </Grid>
        <Grid item md={12} lg={6}>
          <StatisticsBox
            label="admin.praxis_stats.nb_patients_atrisk"
            value={stats.ChangeRiskPatients.ChangePP}
            color="lightblue"
            secondaryText={t("admin.praxis_stats.absolut_vs", {
              baseline: stats.ChangeRiskPatients.BaselineNb,
              screening: stats.ChangeRiskPatients.ScreeningNb,
            })}
          />
        </Grid>
        <Grid item md={12} lg={6}>
          <StatisticsBox
            label="admin.praxis_stats.nb_patients_tested"
            value={stats.ChangeTestedPatients.ChangePP}
            color="secondary"
            secondaryText={t(
              "admin.praxis_stats.in_percentage_of_patients_atrisk"
            )}
          />
        </Grid>
        <Grid item md={12} lg={6}>
          <StatisticsBox
            label="admin.praxis_stats.nb_ckd_diagnoses"
            value={stats.ChangeDiagnosedPatients.ChangePP}
            color="primary"
            secondaryText={t(
              "admin.praxis_stats.in_percentage_of_patients_atrisk"
            )}
          />
        </Grid>
      </Grid>
    </>
  ) : (
    <NoStatistics t={t} />
  );
};

export default PraxisStats;
